import React from "react";
import { Table, Button, Input, Space, Modal } from 'antd';
// import config from '../config.json';
import { openNotification } from "../functions";
import 'dayjs/locale/ru';
import {
  EditOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';

export default class Platforms extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      platforms: "",
      newPlatformId: "",
      newPlatformName: "",
      newPlatformLink: "",
      modalAdd: false,
      editPlatform: false
    };
  }

  getPlatforms() {
    fetch(`https://student-control.nti-center.ru/api/platforms`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', response.error, 1, 4.5);
      } else {
        var arr = [];
        response.data.map((platform) => {
          arr.push({
            key: platform.platform_id,
            platform_name: platform.platform_name,
            platform_link: platform.platform_link,
            events: <Tooltip title="Изменить">
              <EditOutlined  onClick={() => {
                this.setState({ newPlatformName: platform.platform_name, newPlatformLink: platform.platform_link, newPlatformId: platform.platform_id, modalAdd: true, editPlatform: true});
              }} style={{ cursor: 'pointer', marginRight: '10px' }} />
            </Tooltip>
          })
        })
        this.setState({ platforms: arr });
      }
    })
  }

  editPlatform() {
    fetch(`https://student-control.nti-center.ru/api/platforms`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        platform_id: this.state.newPlatformId,
        platform_link: this.state.newPlatformLink,
        platform_name: this.state.newPlatformName
      })
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', response.error, 1, 4.5);
      } else {
        openNotification('Успех', "Платформа изменена", 3, 4.5);
        this.setState({editPlatform: false, newPlatformId: "", newPlatformLink: "", newPlatformName: "", modalAdd: false})
        this.getPlatforms();
      }
    })
  }

  addPlatform() {
    fetch(`https://student-control.nti-center.ru/api/platforms`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        platform_link: this.state.newPlatformLink,
        platform_name: this.state.newPlatformName
      })
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', response.error, 1, 4.5);
      } else {
        openNotification('Успех', "Платформа добавлена", 3, 4.5);
        this.setState({newPlatformId: "", newPlatformLink: "", newPlatformName: "", modalAdd: false})
        this.getPlatforms();
      }
    })
  }

  
  ChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }


  render() {
    const columns = [
      {
        title: 'Название',
        dataIndex: 'platform_name',
        key: 'platform_name'
      },
      {
        title: 'Ссылка',
        dataIndex: 'platform_link',
        key: 'platform_link',
        render: (_, record) => (
            <a target={"_blank"} rel="noreferrer" href={record.platform_link}>{record.platform_link}</a>
        ),
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
        
      }
    ];
    return (
      <div >
        <div className="page_name">Платформы обучения</div>
        <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ modalAdd: true, newPlatformLink: "", newPlatformName: "" }); }}>Добавить платформу</Button>
        <Table columns={columns} dataSource={this.state.platforms} />

        <Modal title={ this.state.editPlatform === true ? "Изменение данных о платформе" : "Добавление платформы"} okText={this.state.editPlatform === true ? "Изменить" : "Добавить"} cancelText="Закрыть" onOk={() => {this.state.editPlatform === true ? this.editPlatform() : this.addPlatform()}} onCancel={() => {this.setState({modalAdd: false})}} open={this.state.modalAdd}>
          <Space direction="vertical" style={{width: "100%"}}>
            <Input type="text" addonBefore="Название" name="newPlatformName" value={this.state.newPlatformName} onChange={this.ChangeInput.bind(this)}/>
            <Input type="text" addonBefore="Ссылка" name="newPlatformLink" value={this.state.newPlatformLink} onChange={this.ChangeInput.bind(this)}/>
          </Space> 
        </Modal>


      </div>
    );
  }

  componentDidMount() {
    this.getPlatforms();
  }
}