import React from "react";
import { Tabs, Button, Space, Popconfirm, Table, Modal } from 'antd';
import { LeftOutlined, EyeOutlined, DeleteOutlined, CheckCircleFilled } from '@ant-design/icons';
import { openNotification } from "../functions";
// import config from '../config.json';

export default class Frdo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabsKey: 1,
      activeStatus: "",
      statusIsActive: false,
      groupsIsActive: false,
      groups: [],
      selectedGroups: [],
      groupFilters: [],
      modal: false,
      loading: false
    };
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          var arr2 = [];
          response.data.map((group) => {
            arr.push({
              key: group.group_id,
              group_name: group.group_name,
              course_name: group.course_name,
              project_year: group.project_year,
              time_start: group.time_start,
              time_end: group.time_end,
              project_name: group.project_name,
            })
            arr2.push({
              text: group.course_name,
              value: group.course_name,
            })
          })
          this.setState({ groups: arr, groupFilters: arr2 });
        }
      })
  }

  statusSelection(status) {
    this.setState({ tabsKey: 2, activeStatus: status, statusIsActive: true });
  }

  rowSelection = (e) => {
    console.log(e);
  }

  getFRDO() {
    
    fetch(`https://student-control.nti-center.ru/api/frdo/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "status": this.state.activeStatus,
        "groups": this.state.selectedGroups
      })
    })
      .then(response => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'frdo.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        this.setState({ tabsKey: 1, activeStatus: "", statusIsActive: false, selectedGroups: [] });
      });
  }

  render() {

    const columns = [
      {
        title: 'Название',
        dataIndex: 'group_name',
        key: 'group_name',
      },
      {
        title: 'Время начала',
        dataIndex: 'time_start',
        key: 'time_start',
      },
      {
        title: 'Время окончания',
        dataIndex: 'time_end',
        key: 'time_end',
      },
      {
        title: 'Курс',
        dataIndex: 'course_name',
        key: 'course_name',
        filters: this.state.groupFilters,
        onFilter: (value, record) => record.course_name.indexOf(value) === 0
      },
      {
        title: 'Проект',
        dataIndex: 'project_name',
        key: 'project_name',
      },
      {
        title: 'Год',
        dataIndex: 'project_year',
        key: 'project_year',
      },

    ]

    return (
      <div>
        <div className="page_name">ФРДО</div>
        <div className="white_block" style={{ width: 'calc(100% - 10px)' }}>
          <Tabs
            activeKey={this.state.tabsKey}
            centered
            items={[
              {
                label: this.state.statusIsActive === true ? `Выберите статус ✔️` : `Выберите статус ❌`,
                key: 1,
                children:
                  <Space>
                    <Button type="dashed" onClick={() => { this.statusSelection('Обучается') }}>Обучается</Button>
                    <Button type="dashed" onClick={() => { this.statusSelection('Обучился') }}>Обучился</Button>
                    <Button type="dashed" onClick={() => { this.statusSelection('Отказался') }}>Отказался</Button>
                    <Button type="dashed" onClick={() => { this.statusSelection('Не сдал итоговую') }}>Не сдал итоговую</Button>
                  </Space>,
              },
              {
                label: `Выберите группы`,
                key: 2,
                children:
                  <div>
                    <Button type="text" onClick={() => { this.setState({ tabsKey: 1, activeStatis: "", statusIsActive: false }) }}> <LeftOutlined style={{ marginLeft: 0 }} /> Назад к выбору статуса</Button>
                    {
                      this.state.selectedGroups.length > 0 ?
                        <Button type="primary" loading={this.state.loading} onClick={() => { this.getFRDO() }}>Создать таблицу ФРДО</Button>
                        :
                        ""
                    }
                    <Table rowSelection={{
                      type: 'checkbox',
                      onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectedGroups: selectedRowKeys });
                      },
                    }} dataSource={this.state.groups} style={{ marginTop: '20px' }} columns={columns} />
                  </div>,
              },
            ]}
          />
        </div>
        {/* <Modal
          title={<div><CheckCircleFilled style={{ color: '#47cd47' }} /> Создание таблицы ФРДО запущено </div>}
          open={this.state.modal}
          onCancel={() => {
            this.setState({ modal: false })
          }}
          footer={[
            <Button key="ok" type="primary" onClick={() => { this.setState({ modal: false }) }}>Закрыть</Button>
          ]}>
          <div>Вы успешно создали запрос на таблицу ФРДО, когда таблица будет готова, мы пришлем вам уведомление.</div>
        </Modal> */}
      </div>
    );
  }

  componentDidMount() {
    this.getGroups();
  }
}