import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Table, Button, Modal, Input, Popconfirm } from 'antd';
import { openNotification } from "../functions";

export default class Projects extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      projects: null,
      newProjectName: '',
      newProjectYear: '',
      newProjectId: '',
      edit: false
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  getProjects() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((project) => {
            arr.push({
              key: project.project_id,
              name: project.project_name,
              year: project.project_year,
              events: <div>
                <EyeOutlined onClick={() => {
                  this.setState({ edit: true, newProjectName: project.project_name, newProjectYear: project.project_year, newProjectId: project.project_id, modal: true })
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить проект?"
                  okText="Да"
                  onConfirm={() => { this.DeleteProject(project.project_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ projects: arr });
        }
      })
  }

  postProject() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "project_name": this.state.newProjectName,
        "project_year": this.state.newProjectYear
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали проект!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getProjects();
        }
      });
  }

  DeleteProject(id) {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        "project_id": id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Проект был удален', 3, 4.5);
          this.getProjects();
        }
      })
  }

  patchProject() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "project_id": this.state.newProjectId,
        "project_name": this.state.newProjectName,
        "project_year": this.state.newProjectYear
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Проект был изменён', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getProjects();
        }
      })
  }

  CheckFields() {
    if (this.state.newProjectYear === "" || this.state.newProjectName === "") {
      return openNotification('Ошибка', 'Заполните пустые поля', 1, 4.5);
    } else {
      if (this.state.newProjectYear.length < 4) {
        return openNotification('Ошибка', 'Неверный формат года', 1, 4.5);
      } else {
        if (this.state.edit === true) {
          this.patchProject();
        } else {
          this.postProject();
        }
      }
    }
  }

  render() {

    const columns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    return (
      <div>
        <div className="page_name">Список проектов</div>
        <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ newProjectName: '', newProjectYear: '', newProjectId: '', edit: false, modal: true }) }}>Добавить проект</Button>
        <Table dataSource={this.state.projects} columns={columns} />

        <Modal title={this.state.edit === true ? "Редактирование проекта" : "Добавление проекта"} open={this.state.modal} okText={this.state.edit === true ? "Сохранить" : "Добавить"} cancelText="Отмена" onOk={() => { this.CheckFields(); }} onCancel={() => { this.setState({ modal: false }) }}>
          <div className="modal_field_name">Название</div>
          <Input name="newProjectName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newProjectName} placeholder="Введите название" />
          <div className="modal_field_name">Год</div>
          <Input name="newProjectYear" type="number" onChange={this.ChangeInputValue.bind(this)} value={this.state.newProjectYear} placeholder="Введите год" />
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getProjects();
  }

}