import axios from "axios";
import { openNotification } from "./functions";

export async function getYaData(link)  {

  let config = {
    headers: {
      authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
    }
  }
  var ent;

  await axios.get('https://cloud-api.yandex.net/v1/disk/resources?path=' + link + '&limit=99999', config).then(function (response) {
    ent = response.data;
  });

  return ent;

}

export async function reqDownloadElement(link)  {

  let config = {
    headers: {
      authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
    }
  }
  var ent;

  await axios.get('https://cloud-api.yandex.net/v1/disk/resources/download?path=' + link, config).then(function (response) {
    ent = response.data;
  });

  return ent;
}

export async function reqDeleteElement(link)  {

  let config = {
    headers: {
      authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
    }
  }
  var ent;

  await axios.delete('https://cloud-api.yandex.net/v1/disk/resources/?path=' + link, config).then(function (response) {
    ent = response.data;
  });

  return ent;
}

export async function reqAddFolder(link)  {

  let config = {
    headers: {
      authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
    }
  }
  var ent;

  await axios.put('https://cloud-api.yandex.net/v1/disk/resources?path=' + link, "", config).then(function (response) {
    ent = response.data;
  });

  return ent;
}

export async function reqAddFile(link)  {

  let config = {
    headers: {
      authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
    }
  }
  var ent;
  try {
    await axios.get('https://cloud-api.yandex.net/v1/disk/resources/upload?path=' + link, config).then(async function (response) {
      if(response.status === 200) {
        let formData = new FormData();
        var imagefile = document.getElementById('upload-file');
        formData.append('file', imagefile.files[0]);
        await axios.put(response.data.href, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: 'OAuth y0_AgAEA7qjld6_AAncDQAAAADicAfP6Y74PhsTTfKUTaZkxRGYYuN22Lw',
          }
        }).then(function (response) {
          ent = response;
          return ent;
        })
      }else{
        openNotification('Ошибка', response.data.description, 1, 4.5);
      }
    });
  } catch(err) {
    openNotification('Ошибка', err, 1, 4.5);
  }
  

  // return ent;
}
