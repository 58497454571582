import React from "react";
import { Table, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
// import config from '../config.json';
import { openNotification } from "../functions";
import Highlighter from 'react-highlight-words';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import axios from "axios";

const { Option } = Select;

export default class Me extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      me: "",
      ClientId: "",
      ClientName: "",
      ClientSurname: "",
      ClientPatronymic: "",
      ClientMail: "",
      ClientWorkMail: "",
      ClientAdditionalMail: "",
      ClientPhone: "",
      ClientWorkPhone: "",
      ClientAdditionalPhone: "",
      ClientCity: "",
      ClientBirthday: "00.00.0000",
      ClientDocDate: "",
      ClientDocNumber: "",
      ClientDocSeries: "",
      ClientFioDiplom: "",
      ClientLevelDiplom: "",
      ClientNumberDiplom: "",
      ClientSeriesDiplom: "",
      ClientPol: "",
      ClientRegistrationNumber: "",
      ClientSnils: "",
      ClientInstitution: "",
      ClientCourseId: "",
      ClientGroupId: "",
      ClientProjectId: "",
      ClientPrice: "",
      ClientCategory: "",
      ClientStatus: "",
      ClientLogin: "",
      ClientPassword: "",
      inputChanged: false,
      courses: "",
      groups: "",
      projects: ""
    };
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ courses: response.data });
        }
      })
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ groups: response.data });
        }
      })
  }

  getProjects() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ projects: response.data });
        }
      })
  }

  getMe() {
    fetch(`https://student-control.nti-center.ru/api/me`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            ClientId: response.data[0].client_id,
            ClientName: response.data[0].client_name,
            ClientSurname: response.data[0].client_surname,
            ClientPatronymic: response.data[0].client_patronymic,
            ClientMail: response.data[0].client_mail,
            ClientWorkMail: response.data[0].client_work_mail,
            ClientAdditionalMail: response.data[0].client_additional_mail,
            ClientPhone: response.data[0].client_phone,
            ClientWorkPhone: response.data[0].client_work_phone,
            ClientAdditionalPhone: response.data[0].client_additional_phone,
            ClientCity: response.data[0].client_city,
            ClientBirthday: response.data[0].birthday,
            ClientDocDate: response.data[0].doc_date,
            ClientDocNumber: response.data[0].doc_number,
            ClientDocSeries: response.data[0].doc_series,
            ClientFioDiplom: response.data[0].fio_diplom,
            ClientLevelDiplom: response.data[0].level_diplom,
            ClientNumberDiplom: response.data[0].number_diplom,
            ClientSeriesDiplom: response.data[0].series_diplom,
            ClientPol: response.data[0].pol,
            ClientRegistrationNumber: response.data[0].registration_number,
            ClientSnils: response.data[0].snils,
            ClientInstitution: response.data[0].institution,
            ClientCourseId: response.data[0].course_id,
            ClientGroupId: response.data[0].group_id,
            ClientProjectId: response.data[0].project_id,
            ClientPrice: response.data[0].priceprice,
            ClientCategory: response.data[0].category,
            ClientStatus: response.data[0].client_status,
            ClientLogin: response.data[0].client_login,
          });
        }
      })
  }

  patchMe() {
    fetch(`https://student-control.nti-center.ru/api/me`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_id" : this.state.ClientId !== "" && this.state.ClientId !== null ? this.state.ClientId : null,
        "client_name" : this.state.ClientName !== "" && this.state.ClientName !== null ? this.state.ClientName : null,
        "client_surname" : this.state.ClientSurname !== "" && this.state.ClientSurname !== null ? this.state.ClientSurname : null,
        "client_patronymic" : this.state.ClientPatronymic !== "" && this.state.ClientPatronymic !== null ? this.state.ClientPatronymic : null,
        "client_mail" : this.state.ClientMail !== "" && this.state.ClientMail !== null ? this.state.ClientMail : null,
        "client_work_mail" : this.state.ClientWorkMail !== "" && this.state.ClientWorkMail !== null ? this.state.ClientWorkMail : null,
        "client_additional_mail" : this.state.ClientAdditionalMail !== "" && this.state.ClientAdditionalMail !== null ? this.state.ClientAdditionalMail : null,
        "client_phone" : this.state.ClientPhone !== "" && this.state.ClientPhone !== null ? this.state.ClientPhone : null,
        "client_work_phone" : this.state.ClientWorkPhone !== "" && this.state.ClientWorkPhone !== null ? this.state.ClientWorkPhone : null,
        "client_additional_phone" : this.state.ClientAdditionalPhone !== "" && this.state.ClientAdditionalPhone !== null ? this.state.ClientAdditionalPhone : null,
        "client_city" : null,
        "birthday" : this.state.ClientBirthday !== "" && this.state.ClientBirthday !== null ? this.state.ClientBirthday : null,
        "doc_date" : this.state.ClientDocDate !== "" && this.state.ClientDocDate !== null ? this.state.ClientDocDate : null,
        "doc_number" : this.state.ClientDocNumber !== "" && this.state.ClientDocNumber !== null ? this.state.ClientDocNumber : null,
        "doc_series" : this.state.ClientDocSeries !== "" && this.state.ClientDocSeries !== null ? this.state.ClientDocSeries : null,
        "fio_diplom" : this.state.ClientFioDiplom !== "" && this.state.ClientFioDiplom !== null ? this.state.ClientFioDiplom : null,
        "level_diplom" : this.state.ClientLevelDiplom !== "" && this.state.ClientLevelDiplom !== null ? this.state.ClientLevelDiplom : null,
        "number_diplom" : this.state.ClientNumberDiplom !== "" && this.state.ClientNumberDiplom !== null ? this.state.ClientNumberDiplom : null,
        "series_diplom" : this.state.ClientSeriesDiplom !== "" && this.state.ClientSeriesDiplom !== null ? this.state.ClientSeriesDiplom : null,
        "pol" : this.state.ClientPol !== "" && this.state.ClientPol !== null ? this.state.ClientPolClientPol : null,
        "registration_number" : this.state.ClientRegistrationNumber !== "" && this.state.ClientRegistrationNumber !== null ? this.state.ClientRegistrationNumber : null,
        "snils" : this.state.ClientSnils !== "" && this.state.ClientSnils !== null ? this.state.ClientSnils : null,
        "institution" : this.state.ClientInstitution !== "" && this.state.ClientInstitution !== null ? this.state.ClientInstitution : null,
        "course_id" : this.state.ClientCourseId !== "" && this.state.ClientCourseId !== null ? this.state.ClientCourseId : null,
        "group_id" : this.state.ClientGroupId !== "" && this.state.ClientGroupId !== null ? this.state.ClientGroupId : null,
        "project_id" : this.state.ClientProjectId !== "" && this.state.ClientProjectId !== null ? this.state.ClientProjectId : null,
        "category" : this.state.ClientCategory !== "" && this.state.ClientCategory !== null ? this.state.ClientCategory : null,
        "client_status" : this.state.ClientStatus !== "" && this.state.ClientStatus !== null ? this.state.ClientStatus : null,
        "client_login" : this.state.ClientLogin !== "" && this.state.ClientLogin !== null ? this.state.ClientLogin : null
      })
    })
    .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.getMe();
          this.setState({inputChanged: false})
        }
      })
  }

  render() {
    if (!localStorage.getItem('token') && window.location.pathname !== '/login') {
      window.location = '/login';
    }
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className="page_name">Мой профиль</div>
          {
            this.state.inputChanged === true ?
              <Button type="primary" onClick={() => {this.patchMe()}}>Сохранить изменения</Button>
              :
              ""
          }

        </div>
        <Row gutter={[16, 16]}>
          <Col flex={1}>
            <Divider orientation="left">Личные данные</Divider>
            <Space direction="vertical">
              <Input addonBefore="Имя:" name="ClientName" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientName} />
              <Input addonBefore="Фамилия:" name="ClientSurname" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientSurname} />
              <Input addonBefore="Отчество:" name="ClientPatronymic" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientPatronymic} />
              <Input addonBefore="Почта:" name="ClientMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientMail} placeholder="xxx@xxx.xx" />
              <Input addonBefore="Раб. почта:" name="ClientWorkMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientWorkMail} placeholder="xxx@xxx.xx" />
              <Input addonBefore="Доп. почта:" name="ClientAdditionalMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientAdditionalMail} placeholder="xxx@xxx.xx" />
              <Input addonBefore="Телефон:" name="ClientPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientPhone} placeholder="+7xxxxxxxxxx" />
              <Input addonBefore="Раб. телефон:" name="ClientWorkPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientWorkPhone} placeholder="+7xxxxxxxxxx" />
              <Input addonBefore="Доп. телефон:" name="ClientAdditionalPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientAdditionalPhone} placeholder="+7xxxxxxxxxx" />
              <Input addonBefore="Фактический адрес:" name="ClientCity" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientCity} />
              <Input.Group compact>
                <span className="input_line_name">День рождения:</span>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    allowClear={false}
                    value={dayjs(this.state.ClientBirthday, 'DD.MM.YYYY')}
                    format={'DD.MM.YYYY'}
                  />
                </ConfigProvider>
              </Input.Group>
              <Input.Group compact>
                <span className="input_line_name">Пол:</span>
                <ConfigProvider locale={locale}>
                  <Select
                    value={this.state.ClientPol}
                    style={{
                      width: 120,
                    }}
                    onChange={(value) => { this.ChangeSelect(value, 'ClientPol') }}
                    options={[
                      {
                        value: 'Жен',
                        label: 'Жен',
                      },
                      {
                        value: 'Муж',
                        label: 'Муж',
                      }
                    ]}
                  />
                </ConfigProvider>
              </Input.Group>
              <Input addonBefore="СНИЛС:" name="ClientSnils" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientSnils} placeholder="xxx-xxx-xxx xx" />
            </Space>
          </Col>
          <Col flex={1}>
            <Divider orientation="left">Данные об образовании</Divider>
            <Space direction="vertical">
              <Input.Group compact>
                <span className="input_line_name">Уровень образования:</span>
                <ConfigProvider locale={locale}>
                  <Select
                    value={this.state.ClientLevelDiplom}
                    style={{
                      width: 200,
                    }}
                    onChange={(value) => { this.ChangeSelect(value, 'ClientLevelDiplom') }}
                    options={[
                      {
                        value: 'Высшее образование',
                        label: 'Высшее образование',
                      },
                      {
                        value: 'Среднее профессиональное образование',
                        label: 'Среднее профессиональное образование',
                      },
                      {
                        value: 'Справка',
                        label: 'Справка',
                      },
                      {
                        value: 'Стаж',
                        label: 'Стаж',
                      },
                      {
                        value: 'Пункт 1 правил формирования ФИС ФРДО',
                        label: 'Пункт 1 правил формирования ФИС ФРДО',
                      }
                    ]}
                  />
                </ConfigProvider>
              </Input.Group>
              <Input addonBefore="ФИО в дипломе:" name="ClientFioDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientFioDiplom} />
              <Input addonBefore="Серия диплома:" name="ClientSeriesDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientSeriesDiplom} />
              <Input addonBefore="Номер диплома:" name="ClientNumberDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientNumberDiplom} />
              <Input addonBefore="Учреждение:" name="ClientInstitution" onChange={this.ChangeInputValue.bind(this)} value={this.state.ClientInstitution} />
            </Space>
          </Col>
          <Col flex={1}>
            <Divider orientation="left">Данные по обучению</Divider>
            <Space direction="vertical">
              <Input.Group compact>
                <span className="input_line_name">Статус:</span>
                <ConfigProvider locale={locale}>
                  <Select
                    value={this.state.ClientStatus}
                    style={{
                      width: 200,
                    }}
                    onChange={(value) => { this.ChangeSelect(value, 'ClientStatus') }}
                    options={[
                      {
                        value: 'Обучается',
                        label: 'Обучается',
                      },
                      {
                        value: 'Обучился',
                        label: 'Обучился',
                      },
                      {
                        value: 'Отказался',
                        label: 'Отказался',
                      },
                      {
                        value: 'Не сдал итоговую',
                        label: 'Не сдал итоговую',
                      }
                    ]}
                  />
                </ConfigProvider>
              </Input.Group>
              <Input addonBefore="Серия документа:" value={this.state.ClientDocSeries} name="ClientDocSeries" onChange={this.ChangeInputValue.bind(this)} />
              <Input addonBefore="Номер документа:" value={this.state.ClientDocNumber} name="ClientDocNumber" onChange={this.ChangeInputValue.bind(this)} />
              <Input addonBefore="Дата документа:" value={this.state.ClientDocDate} name="ClientDocDate" onChange={this.ChangeInputValue.bind(this)} />
              <Input addonBefore="Регистрационный номер:" value={this.state.ClientRegistrationNumber} name="ClientRegistrationNumber" onChange={this.ChangeInputValue.bind(this)} />
              <Input.Group compact>
                <span className="input_line_name">Группа:</span>
                <ConfigProvider locale={locale}>
                  <Select
                    value={this.state.ClientGroupId}
                    style={{
                      width: 200,
                    }}
                    onChange={(value) => { this.ChangeSelect(value, 'ClientGroupId'); this.setState({ ClientCourseId: this.state.groups.find(group => group.group_id == value).course_id, ClientProjectId: this.state.groups.find(group => group.group_id == value).project_id }) }}
                  >
                    {
                      this.state.groups.length !== 0 ?
                        this.state.groups.map((item, index) => {
                          return (
                            <Option key={index} value={item.group_id}>
                              {item.group_name}
                            </Option>
                          )
                        }) : "Список пустой :("
                    }
                  </Select>
                </ConfigProvider>
              </Input.Group>
              <Input addonBefore="Курс:" disabled value={this.state.ClientGroupId !== "" && this.state.ClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.ClientGroupId).course_name : ""} />
              <Input addonBefore="Проект:" disabled value={this.state.ClientGroupId !== "" && this.state.ClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.ClientGroupId).project_name : ""} />
              <Input addonBefore="Год:" disabled value={this.state.ClientGroupId !== "" && this.state.ClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.ClientGroupId).project_year : ""} />
              <Input addonBefore="Цена:" value={this.state.ClientPrice} name="ClientPrice" onChange={this.ChangeInputValue.bind(this)} />
              <Input.Group compact>
                <span className="input_line_name">Категория:</span>
                <ConfigProvider locale={locale}>
                  <Select
                    value={this.state.ClientCategory}
                    style={{
                      width: 200,
                    }}
                    onChange={(value) => { this.ChangeSelect(value, 'ClientCategory') }}
                    options={[
                      {
                        value: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                        label: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                      },
                      {
                        value: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                        label: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                      },
                      {
                        value: 'Студенты',
                        label: 'Студенты',
                      },
                      {
                        value: 'Граждане с заработной платой ниже средней по субъекту РФ',
                        label: 'Граждане с заработной платой ниже средней по субъекту РФ',
                      },
                      {
                        value: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                        label: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                      },
                      {
                        value: 'Инвалиды',
                        label: 'Инвалиды',
                      },
                      {
                        value: 'Работники бюджетных организаций',
                        label: 'Работники бюджетных организаций',
                      }
                    ]}
                  />
                </ConfigProvider>
              </Input.Group>
            </Space>
          </Col>
        </Row>
      </div>
    );
  }

  componentDidMount() {
    this.getMe();
  }

}