import React from "react";
// import config from '../config.json';
import { openNotification } from "../functions";

export default class Learning extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      url: "https://edu.proctor-lms.ru"
    };
  }

  render() {
    return (
      <div>
        <form id="login" target="frame" method="post" action="https://edu.proctor-lms.ru/login/index.php">
            <input type="hidden" name="username" value="lms@nti-center.ru" />
            <input type="hidden" name="password" value="EvdQ3GG9W" />
            <input type="hidden" name="logintoken" value="" />
        </form>

        <iframe 
        title="lms" 
        id="frame"
        className="learning_page" 
        src={this.state.url}
        allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"
        >
          
        </iframe>
      </div>
    );
  }

  componentDidMount () {
    fetch(`https://student-control.nti-center.ru/api/me/platform`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        openNotification('Ошибка', "", 1, 4.5);
      } else {
        if(response.data[0].platform_name === "edu.proctor"){
          this.setState({url: response.data[0].platform_link})
          
        }else{
          this.setState({url: response.data[0].platform_link})
        }
      }
    })


    

  }
}