import React from "react";
import { Tabs, Button, Space, Table, Checkbox, Row, Col, Input, Select, ConfigProvider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { openNotification } from "../functions";
// import config from '../config.json';
import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';

const { Option } = Select;

export default class Unloading extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabsKey: 1,
      activeStatus: "",
      statusIsActive: false,
      groupsIsActive: false,
      filtersIsActive: false,
      groups: [],
      selectedGroups: [],
      groupFilters: [],
      modal: false,
      loading: false,
      selectedFields: [],
      filterPol: "Любой",
      filterStatus: "Любой",
      filterCategory: "Любой",
      fieldName: true,
      fieldSurname: true,
      fieldPatronymic: true,
      fieldMail: true,
      fieldWorkMail: false,
      fieldAdditionalMail: false,
      fieldPhone: true,
      fieldWorkPhone: false,
      fieldAdditionalPhone: false,
      fieldCity: false,
      fieldBirthday: false,
      fieldDocDate: false,
      fieldDocNumber: false,
      fieldDocSeries: false,
      fieldFioDiplom: false,
      fieldLevelDiplom: false,
      fieldNumberDiplom: false,
      fieldSeriesDiplom: false,
      fieldPol: false,
      fieldRegistrationNumber: false,
      fieldSnils: false,
      fieldInstitution: false,
      fieldCourse: false,
      fieldGroup: false,
      fieldProject: false,
      fieldPrice: false,
      fieldCategory: false,
      fieldStatus: false,
      fieldYear: false
    };
  }

  allFields() {
    this.setState({
      fieldName: true,
      fieldSurname: true,
      fieldPatronymic: true,
      fieldMail: true,
      fieldWorkMail: true,
      fieldAdditionalMail: true,
      fieldPhone: true,
      fieldWorkPhone: true,
      fieldAdditionalPhone: true,
      fieldCity: true,
      fieldBirthday: true,
      fieldDocDate: true,
      fieldDocNumber: true,
      fieldDocSeries: true,
      fieldFioDiplom: true,
      fieldLevelDiplom: true,
      fieldNumberDiplom: true,
      fieldSeriesDiplom: true,
      fieldPol: true,
      fieldRegistrationNumber: true,
      fieldSnils: true,
      fieldInstitution: true,
      fieldCourse: true,
      fieldGroup: true,
      fieldProject: true,
      fieldPrice: true,
      fieldCategory: true,
      fieldStatus: true,
      fieldYear: true,
    })
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          var arr2 = [];
          response.data.map((group) => {
            arr.push({
              key: group.group_id,
              group_name: group.group_name,
              course_name: group.course_name,
              project_year: group.project_year,
              time_start: group.time_start,
              time_end: group.time_end,
              project_name: group.project_name,
            })
            arr2.push({
              text: group.course_name,
              value: group.course_name,
            })
          })
          this.setState({ groups: arr, groupFilters: arr2 });
        }
      })
  }

  ChangeSelect(value, name) {
    this.setState({ [name]: value, filtersIsActive: true });
  }

  onChangeCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  getUnloading() {

    fetch(`https://student-control.nti-center.ru/api/unloading`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "groups": this.state.selectedGroups,
        "filterCategory": this.state.filterCategory,
        "filterPol": this.state.filterPol,
        "filterStatus": this.state.filterStatus,
        "fieldName": this.state.fieldName,
        "fieldSurname": this.state.fieldSurname,
        "fieldPatronymic": this.state.fieldPatronymic,
        "fieldMail": this.state.fieldMail,
        "fieldWorkMail": this.state.fieldWorkMail,
        "fieldAdditionalMail": this.state.fieldAdditionalMail,
        "fieldPhone": this.state.fieldPhone,
        "fieldWorkPhone": this.state.fieldWorkPhone,
        "fieldAdditionalPhone": this.state.fieldAdditionalPhone,
        "fieldCity": this.state.fieldCity,
        "fieldBirthday": this.state.fieldBirthday,
        "fieldDocDate": this.state.fieldDocDate,
        "fieldDocNumber": this.state.fieldDocNumber,
        "fieldDocSeries": this.state.fieldDocSeries,
        "fieldFioDiplom": this.state.fieldFioDiplom,
        "fieldLevelDiplom": this.state.fieldLevelDiplom,
        "fieldNumberDiplom": this.state.fieldNumberDiplom,
        "fieldSeriesDiplom": this.state.fieldSeriesDiplom,
        "fieldPol": this.state.fieldPol,
        "fieldRegistrationNumber": this.state.fieldRegistrationNumber,
        "fieldSnils": this.state.fieldSnils,
        "fieldInstitution": this.state.fieldInstitution,
        "fieldCourse": this.state.fieldCourse,
        "fieldGroup": this.state.fieldGroup,
        "fieldProject": this.state.fieldProject,
        "fieldPrice": this.state.fieldPrice,
        "fieldCategory": this.state.fieldCategory,
        "fieldStatus": this.state.fieldStatus,
        "fieldYear": this.state.fieldYear
      })
    })
      .then(response => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'unloading.xlsx'
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        this.setState({ tabsKey: 1, activeStatus: "", statusIsActive: false, selectedGroups: [] });
      });
  }

  render() {
    const columns = [
      {
        title: 'Название',
        dataIndex: 'group_name',
        key: 'group_name',
      },
      {
        title: 'Время начала',
        dataIndex: 'time_start',
        key: 'time_start',
      },
      {
        title: 'Время окончания',
        dataIndex: 'time_end',
        key: 'time_end',
      },
      {
        title: 'Курс',
        dataIndex: 'course_name',
        key: 'course_name',
        filters: this.state.groupFilters,
        onFilter: (value, record) => record.course_name.indexOf(value) === 0
      },
      {
        title: 'Проект',
        dataIndex: 'project_name',
        key: 'project_name',
      },
      {
        title: 'Год',
        dataIndex: 'project_year',
        key: 'project_year',
      }

    ]
    return (
      <div className="content">
        <div className="page_name">Выгрузка</div>
        <div className="white_block" style={{ width: 'calc(100% - 10px)' }}>
          <Tabs
            activeKey={this.state.tabsKey}
            centered
            items={[
              {
                label: this.state.groupsIsActive === true ? `Выберите группы ✔️` : `Выберите группы ❌`,
                key: 1,
                children:
                  <div>
                    {
                      this.state.selectedGroups.length > 0 ?
                        <Button type="primary" onClick={() => { this.setState({ tabsKey: 2, groupsIsActive: true }); }}>Выбрать фильтры</Button>
                        :
                        ""
                    }
                    <Table rowSelection={{
                      type: 'checkbox',
                      onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ selectedGroups: selectedRowKeys });
                      },
                    }} dataSource={this.state.groups} style={{ marginTop: '20px' }} columns={columns} />
                  </div>,
              },
              {
                label: this.state.filtersIsActive === true ? `Выберите фильтры ✔️` : `Выберите фильтры ❌`,
                key: 2,
                children:
                  <div>
                    <Button type="text" onClick={() => { this.setState({ tabsKey: 1 }) }}> <LeftOutlined style={{ marginLeft: 0 }} /> Назад к выбору групп</Button>
                    <Button type="primary" onClick={() => { this.setState({ tabsKey: 3, filtersIsActive: true }) }}>Выбрать поля выгрузки</Button>

                    <Space direction="vertical" size="middle" style={{ display: 'flex', marginTop: 20 }}>
                      <Input.Group compact>
                        <span className="input_line_name">Пол:</span>
                        <ConfigProvider locale={locale}>
                          <Select
                            value={this.state.filterPol}
                            style={{
                              width: 120,
                            }}
                            onChange={(value) => { this.ChangeSelect(value, 'filterPol') }}
                            options={[
                              {
                                value: 'Жен',
                                label: 'Жен',
                              },
                              {
                                value: 'Муж',
                                label: 'Муж',
                              },
                              {
                                value: 'Любой',
                                label: 'Любой',
                              }
                            ]}
                          />
                        </ConfigProvider>
                      </Input.Group>
                      <Input.Group compact>
                        <span className="input_line_name">Статус:</span>
                        <ConfigProvider locale={locale}>
                          <Select
                            value={this.state.filterStatus}
                            style={{
                              width: 200,
                            }}
                            onChange={(value) => { this.ChangeSelect(value, 'filterStatus') }}
                            options={[
                              {
                                value: 'Обучается',
                                label: 'Обучается',
                              },
                              {
                                value: 'Обучился',
                                label: 'Обучился',
                              },
                              {
                                value: 'Отказался',
                                label: 'Отказался',
                              },
                              {
                                value: 'Не сдал итоговую',
                                label: 'Не сдал итоговую',
                              },
                              {
                                value: 'Любой',
                                label: 'Любой',
                              }
                            ]}
                          />
                        </ConfigProvider>
                      </Input.Group>
                      <Input.Group compact>
                        <span className="input_line_name">Категория:</span>
                        <ConfigProvider locale={locale}>
                          <Select
                            value={this.state.filterCategory}
                            style={{
                              width: 400,
                            }}
                            onChange={(value) => { this.ChangeSelect(value, 'filterCategory') }}
                            options={[
                              {
                                value: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                                label: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                              },
                              {
                                value: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                                label: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                              },
                              {
                                value: 'Студенты',
                                label: 'Студенты',
                              },
                              {
                                value: 'Граждане с заработной платой ниже средней по субъекту РФ',
                                label: 'Граждане с заработной платой ниже средней по субъекту РФ',
                              },
                              {
                                value: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                                label: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                              },
                              {
                                value: 'Инвалиды',
                                label: 'Инвалиды',
                              },
                              {
                                value: 'Работники бюджетных организаций',
                                label: 'Работники бюджетных организаций',
                              },
                              {
                                value: 'Любой',
                                label: 'Любой',
                              }
                            ]}
                          />
                        </ConfigProvider>
                      </Input.Group>
                    </Space>
                  </div>,
              },
              {
                label: `Выберите поля выгрузки`,
                key: 3,
                children:
                  <div>
                    <Button type="text" onClick={() => { this.setState({ tabsKey: 2 }) }}> <LeftOutlined style={{ marginLeft: 0 }} /> Назад к выбору фильтров</Button>
                    <Button type="text" loading={this.state.loading} onClick={() => { this.allFields() }}>Выбрать все поля</Button>
                    <Button type="primary" loading={this.state.loading} onClick={() => { this.getUnloading(); }}>Выгрузить</Button>
                    <Row style={{ marginTop: 20 }}>
                      <Col span={8}>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                          <Checkbox checked={this.state.fieldName} name="fieldName" onChange={this.onChangeCheckbox.bind(this)}>Имя</Checkbox>
                          <Checkbox checked={this.state.fieldSurname} name="fieldSurname" onChange={this.onChangeCheckbox.bind(this)}>Фамилия</Checkbox>
                          <Checkbox checked={this.state.fieldPatronymic} name="fieldPatronymic" onChange={this.onChangeCheckbox.bind(this)}>Отчество</Checkbox>
                          <Checkbox checked={this.state.fieldMail} name="fieldMail" onChange={this.onChangeCheckbox.bind(this)}>Почта</Checkbox>
                          <Checkbox checked={this.state.fieldWorkMail} name="fieldWorkMail" onChange={this.onChangeCheckbox.bind(this)}>Раб. почта</Checkbox>
                          <Checkbox checked={this.state.fieldAdditionalMail} name="fieldAdditionalMail" onChange={this.onChangeCheckbox.bind(this)}>Доп. почта</Checkbox>
                          <Checkbox checked={this.state.fieldPhone} name="fieldPhone" onChange={this.onChangeCheckbox.bind(this)}>Телефон</Checkbox>
                          <Checkbox checked={this.state.fieldWorkPhone} name="fieldWorkPhone" onChange={this.onChangeCheckbox.bind(this)}>Раб. телефон</Checkbox>
                          <Checkbox checked={this.state.fieldAdditionalPhone} name="fieldAdditionalPhone" onChange={this.onChangeCheckbox.bind(this)}>Доп. телефон</Checkbox>
                          <Checkbox checked={this.state.fieldCity} name="fieldCity" onChange={this.onChangeCheckbox.bind(this)}>Фактический адрес</Checkbox>
                          <Checkbox checked={this.state.fieldBirthday} name="fieldBirthday" onChange={this.onChangeCheckbox.bind(this)}>День рождения</Checkbox>
                          <Checkbox checked={this.state.fieldPol} name="fieldPol" onChange={this.onChangeCheckbox.bind(this)}>Пол</Checkbox>
                          <Checkbox checked={this.state.fieldSnils} name="fieldSnils" onChange={this.onChangeCheckbox.bind(this)}>СНИЛС</Checkbox>
                        </Space>
                      </Col>
                      <Col span={8}>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                          <Checkbox checked={this.state.fieldLevelDiplom} name="fieldLevelDiplom" onChange={this.onChangeCheckbox.bind(this)}>Уровень образования</Checkbox>
                          <Checkbox checked={this.state.fieldFioDiplom} name="fieldFioDiplom" onChange={this.onChangeCheckbox.bind(this)}>ФИО в дипломе</Checkbox>
                          <Checkbox checked={this.state.fieldSeriesDiplom} name="fieldSeriesDiplom" onChange={this.onChangeCheckbox.bind(this)}>Серия диплома</Checkbox>
                          <Checkbox checked={this.state.fieldNumberDiplom} name="fieldNumberDiplom" onChange={this.onChangeCheckbox.bind(this)}>Номер диплома</Checkbox>
                          <Checkbox checked={this.state.fieldInstitution} name="fieldInstitution" onChange={this.onChangeCheckbox.bind(this)}>Учреждение</Checkbox>
                        </Space>
                      </Col>
                      <Col span={8}>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                          <Checkbox checked={this.state.fieldStatus} name="fieldStatus" onChange={this.onChangeCheckbox.bind(this)}>Статус</Checkbox>
                          <Checkbox checked={this.state.fieldDocSeries} name="fieldDocSeries" onChange={this.onChangeCheckbox.bind(this)}>Серия документа</Checkbox>
                          <Checkbox checked={this.state.fieldDocNumber} name="fieldDocNumber" onChange={this.onChangeCheckbox.bind(this)}>Номер документа</Checkbox>
                          <Checkbox checked={this.state.fieldDocDate} name="fieldDocDate" onChange={this.onChangeCheckbox.bind(this)}>Дата документа</Checkbox>
                          <Checkbox checked={this.state.fieldRegistrationNumber} name="fieldRegistrationNumber" onChange={this.onChangeCheckbox.bind(this)}>Регистрационный номер</Checkbox>
                          <Checkbox checked={this.state.fieldGroup} name="fieldGroup" onChange={this.onChangeCheckbox.bind(this)}>Группа</Checkbox>
                          <Checkbox checked={this.state.fieldCourse} name="fieldCourse" onChange={this.onChangeCheckbox.bind(this)}>Курс</Checkbox>
                          <Checkbox checked={this.state.fieldProject} name="fieldProject" onChange={this.onChangeCheckbox.bind(this)}>Проект</Checkbox>
                          <Checkbox checked={this.state.fieldYear} name="fieldYear" onChange={this.onChangeCheckbox.bind(this)}>Год</Checkbox>
                          <Checkbox checked={this.state.fieldPrice} name="fieldPrice" onChange={this.onChangeCheckbox.bind(this)}>Цена</Checkbox>
                          <Checkbox checked={this.state.fieldCategory} name="fieldCategory" onChange={this.onChangeCheckbox.bind(this)}>Категория</Checkbox>
                        </Space>
                      </Col>
                    </Row>
                  </div>,
              },
            ]}
          />
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.getGroups();
  }
}