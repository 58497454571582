import React from "react";
import { Card, Col, Row, Statistic, Table } from 'antd';
import { TeamOutlined, UnorderedListOutlined, BankOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { json } from "react-router-dom";

export default class Statistics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      graphInfo: ''
    };
  }

  asyncFetch = () => {
    fetch('https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json')
      .then((response) => response.json())
      .then(json => {
        this.setState({ graphInfo: json });
      })
  };

  render() {

    const dataSource = [
      {
        key: '1',
        name: 'Mike',
        event: 'Удаление',
        entity: 'клиент Садыков Дамир Камилович',
      },
      {
        key: '2',
        name: 'John',
        event: 'Добавление',
        entity: 'проект ЦП 2022',
      },
    ];

    const columns = [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Событие',
        dataIndex: 'event',
        key: 'event',
      },
      {
        title: 'Сущность',
        dataIndex: 'entity',
        key: 'entity',
      },
    ];

    var configLine = {
      data: [
        {
          "Date": "2010-01",
          "scales": 1998
        },
        {
          "Date": "2010-02",
          "scales": 1850
        },
        {
          "Date": "2010-03",
          "scales": 1720
        },
        {
          "Date": "2011-02",
          "scales": 1824
        },
        {
          "Date": "2011-03",
          "scales": 1801
        },
        {
          "Date": "2011-04",
          "scales": 2001
        },
        {
          "Date": "2011-05",
          "scales": 1640
        },
        {
          "Date": "2011-06",
          "scales": 1502
        },
        {
          "Date": "2011-07",
          "scales": 1621
        },
        {
          "Date": "2011-08",
          "scales": 1480
        },
        {
          "Date": "2011-09",
          "scales": 1549
        },
        {
          "Date": "2011-10",
          "scales": 1390
        },
        {
          "Date": "2011-11",
          "scales": 1325
        },
        {
          "Date": "2011-12",
          "scales": 1250
        },
      ],
      padding: 'auto',
      xField: 'Date',
      yField: 'scales',
      xAxis: {
        // type: 'timeCat',
        tickCount: 5,
      },
      smooth: true,
    };

    return (
      <div>
        <Row gutter={16}>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Пользователей"
                value={2}
                prefix={<TeamOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Проектов за год"
                value={2}
                prefix={<UnorderedListOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Курсов за год"
                value={2}
                prefix={<BankOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Групп за год"
                value={2}
                prefix={<AppstoreAddOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <div style={{ marginTop: 16 }} className="page_name">Действия пользователей</div>
        <Table
          dataSource={dataSource}
          style={{
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            background: "white",
            borderRadius: "8px"
          }}
          columns={columns}
        />

        <div style={{ marginTop: 16 }} className="page_name">Отчет по клиентам</div>
        <Row gutter={16}>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучаются сейчас в этом году"
                value={2}
                prefix={<TeamOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились в этом году"
                value={2}
                prefix={<UnorderedListOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Отказались в этом году"
                value={2}
                prefix={<BankOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Не сдали итоговую в этом году"
                value={2}
                prefix={<AppstoreAddOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучаются сейчас"
                value={2}
                prefix={<TeamOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились за все время"
                value={2}
                prefix={<UnorderedListOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Отказались за все время"
                value={2}
                prefix={<BankOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Не сдали итоговую за все время"
                value={2}
                prefix={<AppstoreAddOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились на проекте Демография 2022"
                value={2}
                prefix={<TeamOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились на проекте ЦП 2022"
                value={2}
                prefix={<UnorderedListOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились на проекте Демография за этот год"
                value={2}
                prefix={<BankOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false}>
              <Statistic
                title="Обучились на проекте ЦП за этот год"
                value={2}
                prefix={<AppstoreAddOutlined />}
              />
            </Card>
          </Col>
        </Row>

        <div style={{ marginTop: 16 }} className="page_name">График притока клиентов</div>
        <Line {...configLine} />;
      </div>
    );
  }

  componentDidMount() {
    this.asyncFetch()
  }
}