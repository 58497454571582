import React from "react";
import 'animate.css';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import { openNotification } from "../functions";
import config from "../config.json";
import logo from "../images/mini_logo.png";
import jwt from 'jwt-decode';

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      test: 0,
      login: "",
      pass: "",
      cotr: 0
    };
  }

  post() {
    fetch(`https://student-control.nti-center.ru/api/login`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        login: this.state.login,
        password: this.state.pass
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.token) {
            localStorage.setItem('token', response.token);
            let token = jwt(response.token);
            localStorage.setItem('rer', token.role);
            this.props.navigateFunc("/main");
          }
        }
      })
  }

  postClient() {
    fetch(`https://student-control.nti-center.ru/api/login/lk`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        login: this.state.login,
        password: this.state.pass
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          if (response.token) {
            localStorage.setItem('token', response.token);
            let token = jwt(response.token);
            localStorage.setItem('rer', token.role);
            token.role === '99' ? this.props.navigateFunc("/me") : this.props.navigateFunc("/main");
          }
        }
      })
  }

  onFinishFailed() {
    openNotification('Ошибка', 'Неверные логин или пароль', 1, 4.5);
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <div className="login_page">
        <div className="login_container_client" style={{translate: this.state.cotr == 0 ? "0px 65px" : "0px 30px"}}>
          <div className="login_title_client" onClick={() => { this.setState({ cotr: 0 }) }}>Вход для клиента</div>
          <Form name="basic" layout={'vertical'} style={{ marginBottom: "-20px", width: "250px", margin: "30px auto" }} initialValues={{ remember: true }} onFinish={() => { this.postClient() }} onFinishFailed={() => { this.onFinishFailed() }}>
            <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста, введите свой логин' }]}>
              <Input placeholder="Логин" value={this.state.login} name="login" onChange={this.onChange.bind(this)} />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введите свой пароль' }]}>
              <Input.Password placeholder="Пароль" value={this.state.pass} name="pass" onChange={this.onChange.bind(this)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" style={{ display: 'block', margin: '0 auto', width: '100%' }} htmlType="submit">
                Войти
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div style={{height: this.state.cotr == 1 ? "500px" : 80, clip: this.state.cotr == 1 ? "rect(0, 400px, 500px, 0)" : "rect(0, 400px, 100px, 0)"}} className="login_container_pers">
          <div className="login_title_pers" onClick={() => { this.setState({ cotr: 1 }) }}>Вход для персонала</div>
          {
            this.state.cotr === 1 ?
              <Form name="basic" layout={'vertical'} style={{ marginBottom: "-20px", width: "250px", margin: "30px auto" }} initialValues={{ remember: true }} onFinish={() => { this.post() }} onFinishFailed={() => { this.onFinishFailed() }}>
                <Form.Item name="username" rules={[{ required: true, message: 'Пожалуйста, введите свой логин' }]}>
                  <Input placeholder="Логин" value={this.state.login} name="login" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введите свой пароль' }]}>
                  <Input.Password placeholder="Пароль" value={this.state.pass} name="pass" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" style={{ display: 'block', margin: '0 auto', width: '100%' }} htmlType="submit">
                    Войти
                  </Button>
                </Form.Item>
              </Form>
              :
              ""
          }
        </div>
        {/* {
          this.state.cotr == 1 ?
            <div>
              <div style={{textAlign: "center", fontSize: "30px", marginBottom: "18px", fontWeight: "700", color: "#bbbbbb"}}>Вход для сотрудников</div>
              <img src={logo} alt="logo" style={{ display: 'block', margin: '0 auto' }} />
              <Form name="basic" layout={'vertical'} style={{marginBottom: "-20px"}} initialValues={{ remember: true }} onFinish={() => { this.post() }} onFinishFailed={() => { this.onFinishFailed() }}>
                <Form.Item label="Логин" name="username" rules={[{ required: true, message: 'Пожалуйста, введите свой логин' }]}>
                  <Input value={this.state.login} name="login" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Пожалуйста, введите свой пароль' }]}>
                  <Input.Password value={this.state.pass} name="pass" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" style={{ display: 'block', margin: '0 auto', width: '200px' }} htmlType="submit">
                    Войти
                  </Button>
                </Form.Item>
              </Form>
              <Button type="text" style={{color: "#bbbbbb",  margin: '0 auto', display: 'block'}} onClick={() => { this.setState({cotr: 0}) }}>Я клиент</Button>
            </div>
            :
            <div>
              <div style={{textAlign: "center", fontSize: "30px", marginBottom: "18px", fontWeight: "700", color: "#bbbbbb"}} >Вход для клиентов</div>
              <img src={logo} alt="logo" style={{ display: 'block', margin: '0 auto' }} />
              <Form name="basic" layout={'vertical'} style={{marginBottom: "-20px"}} initialValues={{ remember: true }} onFinish={() => { this.post() }} onFinishFailed={() => { this.onFinishFailed() }}>
                <Form.Item label="Логин" name="username" rules={[{ required: true, message: 'Пожалуйста, введите свой логин' }]}>
                  <Input value={this.state.login} name="login" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Пожалуйста, введите свой пароль' }]}>
                  <Input.Password value={this.state.pass} name="pass" onChange={this.onChange.bind(this)} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" style={{ display: 'block', margin: '0 auto', width: '200px' }} htmlType="submit">
                    Войти
                  </Button>
                </Form.Item>
              </Form>
              <Button type="text" style={{color: "#302e2e", margin: '0 auto', display: 'block'}} onClick={() => { this.setState({cotr: 1}) }}>Я сотрудник</Button>
            </div>
        } */}

      </div>
    );
  }

  componentDidMount() {
    localStorage.removeItem("token");
    localStorage.removeItem("rer");
  }

}