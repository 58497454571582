import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Table, Button, Modal, Input, Popconfirm, Select } from 'antd';
import { openNotification } from "../functions";

var dataSource = [];

export default class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      users: null,
      newUserId: '',
      newUserName: '',
      newUserSurname: '',
      newUserPatronymic: '',
      newUserRole: 2,
      newUserLogin: '',
      newUserPassword: '',
      newUserPasswordRepeat: '',
      hidePass: false
    };
  }

  getUsers() {
    fetch(`https://student-control.nti-center.ru/api/users`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          if(response.error == 'tokenError') {
            localStorage.removeItem('token');
          }
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((user) => {
            arr.push({
              key: user.id,
              name: user.name,
              surname: user.surname,
              patronymic: user.patronymic,
              role: user.role,
              events: <div>
                <EyeOutlined onClick={() => {
                  this.setState({ newUserId: user.id, newUserName: user.name, newUserSurname: user.surname, newUserPatronymic: user.patronymic, newUserRole: user.role, newUserLogin: user.login, hidePass: true, modal: true })
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить пользователя?"
                  okText="Да"
                  onConfirm={() => { this.DeleteUser(user.id) }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ users: arr });
        }
      })
  }

  postNewUser() {
    console.log(
      {
        "name": this.state.newUserName,
        "surname": this.state.newUserSurname,
        "patronymic": this.state.newUserPatronymic,
        "role": this.state.newUserRole,
        "login": this.state.newUserLogin,
        "password": this.state.newUserPassword,
      }
    );
    fetch(`https://student-control.nti-center.ru/api/users`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "name": this.state.newUserName,
        "surname": this.state.newUserSurname,
        "patronymic": this.state.newUserPatronymic,
        "role": this.state.newUserRole,
        "login": this.state.newUserLogin,
        "password": this.state.newUserPassword,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали пользователя!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getUsers();
        }
      });
  }

  DeleteUser(id) {
    fetch(`https://student-control.nti-center.ru/api/users`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        "id": id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Пользователь был удален', 3, 4.5);
          this.getUsers();
        }
      })
  }

  PatchUser() {
    fetch(`https://student-control.nti-center.ru/api/users`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "id": this.state.newUserId,
        "name": this.state.newUserName,
        "surname": this.state.newUserSurname,
        "patronymic": this.state.newUserPatronymic,
        "role": this.state.newUserRole,
        "login": this.state.newUserLogin
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Пользователь был изменён', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getUsers();
        }
      })
  }

  CheckFields() {
    if (this.state.hidePass === true) {
      if (this.state.newUserName === "" || this.state.newUserSurname === "" || this.state.newUserPatronymic === "" || this.state.newUserRole === "" || this.state.newUserLogin === "") {
        return openNotification('Ошибка', 'Заполните пустые поля', 1, 4.5);
      } else {
        if (this.state.newUserPassword !== this.state.newUserPasswordRepeat) {
          return openNotification('Ошибка', 'Поля с паролем не совпадают. Они должны быть идентичными', 1, 4.5);
        }
        this.PatchUser();
      }
    } else {
      if (this.state.newUserName === "" || this.state.newUserSurname === "" || this.state.newUserPatronymic === "" || this.state.newUserRole === "" || this.state.newUserLogin === "" || this.state.newUserPassword === "" || this.state.newUserPasswordRepeat === "") {
        return openNotification('Ошибка', 'Заполните пустые поля', 1, 4.5);
      } else {
        if (this.state.newUserPassword !== this.state.newUserPasswordRepeat) {
          return openNotification('Ошибка', 'Поля с паролем не совпадают. Они должны быть идентичными', 1, 4.5);
        }
        this.postNewUser();
      }
    }
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  ChangeSelect(value) {
    this.setState({ newUserRole: value });
  }

  render() {
    const columns = [
      {
        title: 'Имя',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Фамилия',
        dataIndex: 'surname',
        key: 'surname',
      },
      {
        title: 'Отчество',
        dataIndex: 'patronymic',
        key: 'patronymic',
      },
      {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    return (
      <div>
        <div className="page_name">Пользователи</div>
        <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ newUserName: '', newUserSurname: '', newUserPatronymic: '', newUserRole: 2, newUserLogin: '', newUserPassword: '', newUserPasswordRepeat: '', hidePass: false, modal: true }) }}>Добавить пользователя</Button>
        <Table dataSource={this.state.users} columns={columns} />

        <Modal title={this.state.hidePass === true ? "Редактирование пользователя" : "Добавление пользователя"} open={this.state.modal} okText={this.state.hidePass ? "Сохранить" : "Добавить"} cancelText="Отмена" onOk={() => { this.CheckFields(); }} onCancel={() => { this.setState({ modal: false }) }}>
          <div className="modal_field_name">Имя</div>
          <Input name="newUserName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserName} placeholder="Введите имя" />
          <div className="modal_field_name">Фамилия</div>
          <Input name="newUserSurname" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserSurname} placeholder="Введите фамилия" />
          <div className="modal_field_name">Отчество</div>
          <Input name="newUserPatronymic" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserPatronymic} placeholder="Введите отчество" />
          <div className="modal_field_name">Роль</div>
          <Select
            style={{
              minWidth: 200,
            }}
            value={this.state.newUserRole}
            onChange={(value) => { this.ChangeSelect(value); }}
            options={[
              {
                value: 1,
                label: 'Администратор',
              },
              {
                value: 2,
                label: 'Пользователь',
              },
              {
                value: 5,
                label: 'СУПЕР МЕГА АДМИН',
              },
            ]}
          />
          <div className="modal_field_name">Логин</div>
          <Input name="newUserLogin" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserLogin} placeholder="Введите логин" />
          {
            this.state.hidePass === false ?
              <div>
                <div className="modal_field_name">Пароль</div>
                <Input name="newUserPassword" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserPassword} placeholder="Введите пароль" />
                <div className="modal_field_name">Подтвердите пароль</div>
                <Input name="newUserPasswordRepeat" onChange={this.ChangeInputValue.bind(this)} value={this.state.newUserPasswordRepeat} placeholder="Повторите пароль" />
              </div>
              :
              ""
          }
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getUsers()
  }

}