import React from "react";
import {
  RedditOutlined,
  BankOutlined,
  IssuesCloseOutlined,
  AppstoreAddOutlined,
  ProjectOutlined,
  PieChartOutlined,
  TeamOutlined,
  DownloadOutlined,
  GroupOutlined,
  HomeOutlined,
  FileOutlined,
  InsertRowLeftOutlined,
  UnorderedListOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import { Card, Space  } from 'antd';
import { openNotification } from "../functions";
// import config from '../config.json';

const { Meta } = Card;

export default class Files extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageName: "Файлы",
      startPage: true
    };
  }

  getFolder(name) {
    fetch(`https://student-control.nti-center.ru/api/files/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "folder": name
      })
    })
    .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          console.log(response.data);
        }
      });
  }

  render() {
    return (
      <div>
        <div className="page_name">{this.state.pageName}</div>

        {
          this.state.startPage === true ? 
            <Space>
              <Card
                className="file_cart"
                hoverable
                style={{
                  width: 240,
                }}
                cover={<TeamOutlined style={{fontSize: "30px"}}/>}
                onClick={() => {this.getFolder("clients");}}
              >
                <Meta className="file_cart_name" title="Клиенты" />
              </Card>
              <Card
                className="file_cart"
                hoverable
                style={{
                  width: 240,
                }}
                cover={<HomeOutlined style={{fontSize: "30px"}}/>}
                onClick={() => {this.getFolder("organization");}}
              >
                <Meta className="file_cart_name" title="Организация" />
              </Card>
            </Space>
          :
          ""
        }
      </div>
    );
  }
}