
import { notification } from 'antd';

export function getToken() {
  return localStorage.getItem('tn');
}

export const openNotification = (title, description, type, duration) => {
  if (type === 1)
    notification.error({ message: title, description: description, placement: 'bottomRight', duration });
  else if (type === 2)
    notification.info({ message: title, description: description, placement: 'bottomRight', duration });
  else
    notification.success({ message: title, description: description, placement: 'bottomRight', duration });
}