
import React from "react";

export default class LoadingScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="loading_screen">
        <img src="/images/loadicon.png" alt="logo" />
      </div>
    );
  }
}
