import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Table, Button, Modal, Input, Popconfirm, Select } from 'antd';
// import config from '../config.json';
import { openNotification } from "../functions";

export default class Courses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      courses: [],
      projects: [],
      newCourseName: '',
      newCourseYear: '',
      newCourseId: '',
      newCourseProjectName: '',
      newCourseProjectId: '',
      edit: false
    };
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((course) => {
            arr.push({
              key: course.course_id,
              name: course.course_name,
              year: course.project_year,
              project_name: course.project_name,
              events: <div>
                <EyeOutlined onClick={() => {
                  this.setState({ edit: true, newCourseName: course.course_name, newCourseYear: course.project_year, newCourseId: course.course_id, newCourseProjectName: course.project_name, newCourseProjectId: course.project_id, modal: true })
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить курс?"
                  okText="Да"
                  onConfirm={() => { this.deleteCourse(course.course_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ courses: arr });
        }
      })
  }

  getProjects() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ projects: response.data });
        }
      })
  }

  postCourse() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "course_name": this.state.newCourseName,
        "project_id": this.state.newCourseProjectId
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали курс!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getCourses();
        }
      });
  }
  
  deleteCourse(id) {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        "course_id": id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Проект был удален', 3, 4.5);
          this.getCourses();
        }
      })
  }

  patchCourse() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "course_id": this.state.newCourseId,
        "course_name": this.state.newCourseName,
        "project_id": this.state.newCourseProjectId,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Курс был изменён', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getCourses();
          this.getProjects();
        }
      })
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  CheckFields() {
    if (this.state.newCourseName === "" || this.state.newCourseProjectId === "") {
      return openNotification('Ошибка', 'Заполните пустые поля', 1, 4.5);
    } else {
      if (this.state.edit === true) {
        this.patchCourse();
      } else {
        this.postCourse();
      }
    }
  }

  render() {

    const columns = [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Проект',
        dataIndex: 'project_name',
        key: 'project_name',
      },
      {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    return (
      <div>
        <div className="page_name">Список курсов</div>
        <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ edit: false, newCourseName: '', newCourseProjectId: '', modal: true }) }}>Добавить курс</Button>
        <Table dataSource={this.state.courses} columns={columns} />

        <Modal title={this.state.edit === true ? "Редактирование курса" : "Добавление курса"} open={this.state.modal} okText={this.state.edit === true ? "Сохранить" : "Добавить"} cancelText="Отмена" onOk={() => { this.CheckFields(); }} onCancel={() => { this.setState({ modal: false }) }}>
          <div className="modal_field_name">Название курса</div>
          <Input name="newCourseName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newCourseName} placeholder="Введите название" />
          <div className="modal_field_name">Проект</div>
          <Select
            style={{
              minWidth: 200,
            }}
            value={this.state.newCourseProjectId}
            onChange={(value) => { this.setState({ newCourseProjectId: value }) }}
            options={
              this.state.projects.map((project) => {
                return {
                  value: project.project_id,
                  label: project.project_name + " " + project.project_year
                }
              })
            }
          />
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getCourses();
    this.getProjects();
  }

}