import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Table, Button, Input, Popconfirm, Space, Drawer, Col, Divider, Row, DatePicker, ConfigProvider, Select, Alert, Modal, notification } from 'antd';
import { SearchOutlined, RedoOutlined, LinkOutlined } from '@ant-design/icons';
// import config from '../config.json';
import { openNotification } from "../functions";
import Highlighter from 'react-highlight-words';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import axios from "axios";
import * as xlsx from 'xlsx';

const { Option } = Select;

export default class Clients extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      loadusers: false,
      clientPassEdit: false,
      parsingFieldsModal: false,
      loadInput: "",
      loadInputName: "Нажмите сюда и выберите файл для загрузки",
      uploadFileJson: [
        {
          "Номер заявки": 123,
        },
      ],
      uploadFileJsonFields: [],
      uploadClientName: null,
      uploadClientSurname: null,
      uploadClientPatronymic: null,
      uploadClientMail: null,
      uploadClientWorkMail: null,
      uploadClientAdditionalMail: null,
      uploadClientPhone: null,
      uploadClientWorkPhone: null,
      uploadClientAdditionalPhone: null,
      uploadClientCity: null,
      uploadClientCity2: null,
      uploadClientBirthday: null,
      uploadClientDocDate: null,
      uploadClientDocNumber: null,
      uploadClientDocSeries: null,
      uploadClientFioDiplom: null,
      uploadClientLevelDiplom: null,
      uploadClientNumberDiplom: null,
      uploadClientSeriesDiplom: null,
      uploadClientPol: null,
      uploadClientRegistrationNumber: null,
      uploadClientSnils: null,
      uploadClientInstitution: null,
      uploadClientCourseId: null,
      uploadClientGroupId: null,
      uploadClientProjectId: null,
      uploadClientPrice: null,
      uploadClientCategory: null,
      uploadClientStatus: null,
      uploadClientFedLink: null,
      clients: [],
      courses: [],
      groups: [],
      projects: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientBirthday: "",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      client_window: false,
      thisNewClient: false,
      inputChanged: false,
      newClientId: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
      platforms: ""
    };
  }

  clearNewClientData() {
    this.setState({
      newClientId: "",
      newClientName: "",
      newClientSurname: "",
      newClientPatronymic: "",
      newClientMail: "",
      newClientWorkMail: "",
      newClientAdditionalMail: "",
      newClientPhone: "",
      newClientWorkPhone: "",
      newClientAdditionalPhone: "",
      newClientCity: "",
      newClientCity2: "",
      newClientBirthday: "00.00.0000",
      newClientDocDate: "",
      newClientDocNumber: "",
      newClientDocSeries: "",
      newClientFioDiplom: "",
      newClientLevelDiplom: "",
      newClientNumberDiplom: "",
      newClientSeriesDiplom: "",
      newClientPol: "",
      newClientRegistrationNumber: "",
      newClientSnils: "",
      newClientInstitution: "",
      newClientCourseId: "",
      newClientGroupId: "",
      newClientProjectId: "",
      newClientPrice: "",
      newClientCategory: "",
      newClientStatus: "",
      newClientLogin: "",
      newClientPassword: "",
      newClientPlatformId: "",
      newClientFedLink: "",
    })
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ courses: response.data });
        }
      })
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ groups: response.data });
        }
      })
  }

  getProjects() {
    fetch(`https://student-control.nti-center.ru/api/projects`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ projects: response.data });
        }
      })
  }

  getColumnSearchProps = (dataIndex, placeName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input ref={this.state.searchInput} placeholder={`Поиск ${placeName}`} value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])} onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{}}>Поиск</Button>
          <Button onClick={() => clearFilters && this.handleReset(clearFilters)} size="small" >Сбросить</Button>
          <Button type="link" size="small" onClick={() => { close(); }}>Закрыть</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.state.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys[0], searchedColumn: dataIndex });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getClientInfo(id) {
    fetch(`https://student-control.nti-center.ru/api/clients/` + id, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({
            newClientBirthday: response.data[0] === undefined ? "00.00.0000" : response.data[0].birthday,
            newClientFioDiplom: response.data[0] === undefined ? "" : response.data[0].fio_diplom,
            newClientSeriesDiplom: response.data[0] === undefined ? "" : response.data[0].series_diplom,
            newClientNumberDiplom: response.data[0] === undefined ? "" : response.data[0].number_diplom,
            newClientLevelDiplom: response.data[0] === undefined ? "" : response.data[0].level_diplom,
            newClientDocSeries: response.data[0] === undefined ? "" : response.data[0].doc_series,
            newClientDocNumber: response.data[0] === undefined ? "" : response.data[0].doc_number,
            newClientDocDate: response.data[0] === undefined ? "" : response.data[0].doc_date,
            newClientRegistrationNumber: response.data[0] === undefined ? "" : response.data[0].registration_number,
            newClientPol: response.data[0] === undefined ? "" : response.data[0].pol,
            newClientSnils: response.data[0] === undefined ? "" : response.data[0].snils,
            newClientCourseId: response.data[0] === undefined ? "" : response.data[0].course_id,
            newClientGroupId: response.data[0] === undefined ? "" : response.data[0].group_id,
            newClientProjectId: response.data[0] === undefined ? "" : response.data[0].project_id,
            newClientPrice: response.data[0] === undefined ? "" : response.data[0].price,
            newClientCategory: response.data[0] === undefined ? "" : response.data[0].category,
            newClientInstitution: response.data[0] === undefined ? "" : response.data[0].institution,
            newClientStatus: response.data[0] === undefined ? "" : response.data[0].client_status,
            newClientLogin: response.data[0] === undefined ? "" : response.data[0].client_login,
            newClientPlatformId: response.data[0] === undefined ? "" : response.data[0].platform_id,
            newClientFedLink: response.data[0] === undefined ? "" : response.data[0].fed_link,
          });
        }
      })
  }

  getPlatforms() {
    fetch(`https://student-control.nti-center.ru/api/platforms`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          console.log(response.data);
          this.setState({ platforms: response.data });
        }
      })
  }

  getClients() {
    fetch(`https://student-control.nti-center.ru/api/clients`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((client) => {
            arr.push({
              key: client.client_id,
              client_name: client.client_name,
              client_surname: client.client_surname,
              client_patronymic: client.client_patronymic,
              client_mail: client.client_mail,
              client_phone: client.client_phone,
              client_city: client.client_city,
              client_category: client.category,
              events: <div>
                {client.fed_link ? <a style={{ cursor: 'pointer', marginRight: '10px' }} href={client.fed_link} rel="noreferrer" target="_blank"><LinkOutlined/></a> : ""}
                <EyeOutlined onClick={() => {
                  this.setState({ client_window: true, newClientId: client.client_id, newClientName: client.client_name, newClientSurname: client.client_surname, newClientPatronymic: client.client_patronymic, newClientMail: client.client_mail, newClientWorkMail: client.client_work_mail, newClientAdditionalMail: client.client_additional_mail, newClientCity: client.client_city, newClientCity2: client.client_city2, newClientPhone: client.client_phone, newClientWorkPhone: client.client_work_phone, newClientAdditionalPhone: client.client_additional_phone });
                  this.getClientInfo(client.client_id);
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить клиента?"
                  okText="Да"
                  onConfirm={() => { this.deleteClient(client.client_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ clients: arr });
        }
      })
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value, inputChanged: true });
  }

  ChangeSelect(value, name) {
    this.setState({ [name]: value, inputChanged: true });
  }

  closeDrawer() {
    this.setState({ client_window: false, thisNewClient: false, inputChanged: false });
  }

  createClient() {

    fetch(`https://student-control.nti-center.ru/api/clients/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_name": this.state.newClientName !== "" && this.state.newClientName !== null ? this.state.newClientName : null,
        "client_surname": this.state.newClientSurname !== "" && this.state.newClientSurname !== null ? this.state.newClientSurname : null,
        "client_patronymic": this.state.newClientPatronymic !== "" && this.state.newClientPatronymic !== null ? this.state.newClientPatronymic : null,
        "client_mail": this.state.newClientMail !== "" && this.state.newClientMail !== null ? this.state.newClientMail : null,
        "client_work_mail": this.state.newClientWorkMail !== "" && this.state.newClientWorkMail !== null ? this.state.newClientWorkMail : null,
        "client_additional_mail": this.state.newClientAdditionalMail !== "" && this.state.newClientAdditionalMail !== null ? this.state.newClientAdditionalMail : null,
        "client_phone": this.state.newClientPhone !== "" && this.state.newClientPhone !== null ? this.state.newClientPhone : null,
        "client_work_phone": this.state.newClientWorkPhone !== "" && this.state.newClientWorkPhone !== null ? this.state.newClientWorkPhone : null,
        "client_additional_phone": this.state.newClientAdditionalPhone !== "" && this.state.newClientAdditionalPhone !== null ? this.state.newClientAdditionalPhone : null,
        "client_city": this.state.newClientCity !== "" && this.state.newClientCity !== null ? this.state.newClientCity : null,
        "client_city2": this.state.newClientCity2 !== "" && this.state.newClientCity2 !== null ? this.state.newClientCity2 : null,
        "course_id": this.state.newClientCourseId !== "" && this.state.newClientCourseId !== null ? this.state.newClientCourseId : null,
        "group_id": this.state.newClientGroupId !== "" && this.state.newClientGroupId !== null ? this.state.newClientGroupId : null,
        "project_id": this.state.newClientProjectId !== "" && this.state.newClientProjectId !== null ? this.state.newClientProjectId : null,
        "client_status": this.state.newClientStatus !== "" && this.state.newClientStatus !== null ? this.state.newClientStatus : null,
        "snils": this.state.newClientSnils !== "" && this.state.newClientSnils !== null ? this.state.newClientSnils : null,
        "pol": this.state.newClientPol !== "" && this.state.newClientPol !== null ? this.state.newClientPol : null,
        "birthday": this.state.newClientBirthday !== "" && this.state.newClientBirthday !== null ? this.state.newClientBirthday : null,
        "fio_diplom": this.state.newClientFioDiplom !== "" && this.state.newClientFioDiplom !== null ? this.state.newClientFioDiplom : null,
        "series_diplom": this.state.newClientSeriesDiplom !== "" && this.state.newClientSeriesDiplom !== null ? this.state.newClientSeriesDiplom : null,
        "number_diplom": this.state.newClientNumberDiplom !== "" && this.state.newClientNumberDiplom !== null ? this.state.newClientNumberDiplom : null,
        "level_diplom": this.state.newClientLevelDiplom !== "" && this.state.newClientLevelDiplom !== null ? this.state.newClientLevelDiplom : null,
        "institution": this.state.newClientInstitution !== "" && this.state.newClientInstitution !== null ? this.state.newClientInstitution : null,
        "doc_series": this.state.newClientDocSeries !== "" && this.state.newClientDocSeries !== null ? this.state.newClientDocSeries : null,
        "doc_number": this.state.newClientDocNumber !== "" && this.state.newClientDocNumber !== null ? this.state.newClientDocNumber : null,
        "doc_date": this.state.newClientDocDate !== "" && this.state.newClientDocDate !== null ? this.state.newClientDocDate : null,
        "registration_number": this.state.newClientRegistrationNumber !== "" && this.state.newClientRegistrationNumber !== null ? this.state.newClientRegistrationNumber : null,
        "category": this.state.newClientCategory !== "" && this.state.newClientCategory !== null ? this.state.newClientCategory : null,
        "price": this.state.newClientPrice !== "" && this.state.newClientPrice !== null ? this.state.newClientPrice : null,
        "client_password": this.state.newClientPassword !== "" && this.state.newClientPassword !== null ? this.state.newClientPassword : null,
        "platform_id": this.state.newClientPlatformId !== "" && this.state.newClientPlatformId !== null ? this.state.newClientPlatformId : null,
        "fed_link": this.state.newClientFedLink !== "" && this.state.newClientFedLink !== null ? this.state.newClientFedLink : null,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали клиента!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false, newClientPassword: "" });
          }
          this.closeDrawer();
          this.getClients();
          this.getGroups();
        }
      });
  }

  patchClient() {
    fetch(`https://student-control.nti-center.ru/api/clients/`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_id": this.state.newClientId,
        "client_name": this.state.newClientName !== "" && this.state.newClientName !== null ? this.state.newClientName : null,
        "client_surname": this.state.newClientSurname !== "" && this.state.newClientSurname !== null ? this.state.newClientSurname : null,
        "client_patronymic": this.state.newClientPatronymic !== "" && this.state.newClientPatronymic !== null ? this.state.newClientPatronymic : null,
        "client_mail": this.state.newClientMail !== "" && this.state.newClientMail !== null ? this.state.newClientMail : null,
        "client_work_mail": this.state.newClientWorkMail !== "" && this.state.newClientWorkMail !== null ? this.state.newClientWorkMail : null,
        "client_additional_mail": this.state.newClientAdditionalMail !== "" && this.state.newClientAdditionalMail !== null ? this.state.newClientAdditionalMail : null,
        "client_phone": this.state.newClientPhone !== "" && this.state.newClientPhone !== null ? this.state.newClientPhone : null,
        "client_work_phone": this.state.newClientWorkPhone !== "" && this.state.newClientWorkPhone !== null ? this.state.newClientWorkPhone : null,
        "client_additional_phone": this.state.newClientAdditionalPhone !== "" && this.state.newClientAdditionalPhone !== null ? this.state.newClientAdditionalPhone : null,
        "client_city": this.state.newClientCity !== "" && this.state.newClientCity !== null ? this.state.newClientCity : null,
        "client_city2": this.state.newClientCity2 !== "" && this.state.newClientCity2 !== null ? this.state.newClientCity2 : null,
        "course_id": this.state.newClientCourseId !== "" && this.state.newClientCourseId !== null ? this.state.newClientCourseId : null,
        "group_id": this.state.newClientGroupId !== "" && this.state.newClientGroupId !== null ? this.state.newClientGroupId : null,
        "project_id": this.state.newClientProjectId !== "" && this.state.newClientProjectId !== null ? this.state.newClientProjectId : null,
        "client_status": this.state.newClientStatus !== "" && this.state.newClientStatus !== null ? this.state.newClientStatus : null,
        "snils": this.state.newClientSnils !== "" && this.state.newClientSnils !== null ? this.state.newClientSnils : null,
        "pol": this.state.newClientPol !== "" && this.state.newClientPol !== null ? this.state.newClientPol : null,
        "birthday": this.state.newClientBirthday !== "" && this.state.newClientBirthday !== null ? this.state.newClientBirthday : null,
        "fio_diplom": this.state.newClientFioDiplom !== "" && this.state.newClientFioDiplom !== null ? this.state.newClientFioDiplom : null,
        "series_diplom": this.state.newClientSeriesDiplom !== "" && this.state.newClientSeriesDiplom !== null ? this.state.newClientSeriesDiplom : null,
        "number_diplom": this.state.newClientNumberDiplom !== "" && this.state.newClientNumberDiplom !== null ? this.state.newClientNumberDiplom : null,
        "level_diplom": this.state.newClientLevelDiplom !== "" && this.state.newClientLevelDiplom !== null ? this.state.newClientLevelDiplom : null,
        "institution": this.state.newClientInstitution !== "" && this.state.newClientInstitution !== null ? this.state.newClientInstitution : null,
        "doc_series": this.state.newClientDocSeries !== "" && this.state.newClientDocSeries !== null ? this.state.newClientDocSeries : null,
        "doc_number": this.state.newClientDocNumber !== "" && this.state.newClientDocNumber !== null ? this.state.newClientDocNumber : null,
        "doc_date": this.state.newClientDocDate !== "" && this.state.newClientDocDate !== null ? this.state.newClientDocDate : null,
        "registration_number": this.state.newClientRegistrationNumber !== "" && this.state.newClientRegistrationNumber !== null ? this.state.newClientRegistrationNumber : null,
        "category": this.state.newClientCategory !== "" && this.state.newClientCategory !== null ? this.state.newClientCategory : null,
        "price": this.state.newClientPrice !== "" && this.state.newClientPrice !== null ? this.state.newClientPrice : null,
        "client_login": this.state.newClientLogin !== "" && this.state.newClientLogin !== null ? this.state.newClientLogin : null,
        "platform_id": this.state.newClientPlatformId !== "" && this.state.newClientPlatformId !== null ? this.state.newClientPlatformId : null,
        "fed_link": this.state.newClientFedLink !== "" && this.state.newClientFedLink !== null ? this.state.newClientFedLink : null,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Клиент успешно изменён!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          // this.closeDrawer();
          this.getClients();
          this.getGroups();
        }
      });
  }

  deleteClient(id) {
    fetch(`https://student-control.nti-center.ru/api/clients`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        "client_id": id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Клиент был удален', 3, 4.5);
          this.getClients();
          this.getGroups();
        }
      })
  }

  uploadFile() {
    const reader = new FileReader();

    reader.readAsArrayBuffer(this.state.loadInput.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const workbookHeaders = xlsx.readFile(data, { sheetRows: 1 });
      const sheetName2 = workbookHeaders.SheetNames;
      const columnsArray = xlsx.utils.sheet_to_json(workbookHeaders.Sheets[sheetName2], { header: 1 })[0];

      const sheetName = workbook.SheetNames;
      const worksheet = workbook.Sheets[sheetName];
      const json = xlsx.utils.sheet_to_json(worksheet);
      let columnHeaders = [];
  
      this.setState({ uploadFileJson: json, loadusers: false, parsingFieldsModal: true, uploadFileJsonFields: columnsArray });
      
      // console.log(columnHeaders);
      
    };
  }

  uploadClients() {
    fetch(`https://student-control.nti-center.ru/api/clients/file`, {
        headers: new Headers({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'CokCRM ' + localStorage.getItem('token'),
          'Access-Control-Expose-Headers': 'Content-Length'
        }),
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
          xlsxJson: this.state.uploadFileJson,
          settings: {
            name : this.state.uploadClientName,
            surname : this.state.uploadClientSurname,
            patronymic : this.state.uploadClientPatronymic,
            mail : this.state.uploadClientMail,
            workMail : this.state.uploadClientWorkMail,
            additionalMail : this.state.uploadClientAdditionalMail,
            phone : this.state.uploadClientPhone,
            workPhone : this.state.uploadClientWorkPhone,
            additionalPhone : this.state.uploadClientAdditionalPhone,
            city : this.state.uploadClientCity,
            city2 : this.state.uploadClientCity2,
            birthday : this.state.uploadClientBirthday,
            docDate : this.state.uploadClientDocDate,
            docNumber : this.state.uploadClientDocNumber,
            docSeries : this.state.uploadClientDocSeries,
            fioDiplom : this.state.uploadClientFioDiplom,
            levelDiplom : this.state.uploadClientLevelDiplom,
            numberDiplom : this.state.uploadClientNumberDiplom,
            seriesDiplom : this.state.uploadClientSeriesDiplom,
            pol : this.state.uploadClientPol,
            registrationNumber : this.state.uploadClientRegistrationNumber,
            snils : this.state.uploadClientSnils,
            institution : this.state.uploadClientInstitution,
            courseId : this.state.uploadClientCourseId,
            groupId : this.state.uploadClientGroupId,
            projectId : this.state.uploadClientProjectId,
            price : this.state.uploadClientPrice,
            category : this.state.uploadClientCategory,
            status : this.state.uploadClientStatus,
            fed_link:  this.state.uploadClientFedLink,
          }
        })
      })
      .then(response => response.json())
        .then(response => {
          if (response.error) {
            openNotification('Ошибка', response.error, 1, 4.5);
          } else {
            openNotification('Успех', 'Клиенты были добавлены', 3, 4.5);
            // this.getClients();
            // this.getGroups();
          }
        })
  }

  editClientPassword() {
    if (this.state.newClientPassword === "") {
      openNotification('Ошибка', "Поле нового пароля пустое", 1, 4.5);
    } else {
      fetch(`https://student-control.nti-center.ru/api/clients/ep`, {
        headers: new Headers({
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'CokCRM ' + localStorage.getItem('token')
        }),
        method: 'PATCH',
        cache: 'no-cache',
        body: JSON.stringify({
          "client_id": this.state.newClientId,
          "client_password": this.state.newClientPassword
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) {
            openNotification('Ошибка', response.error, 1, 4.5);
          } else {
            this.setState({ clientPassEdit: false, newClientPassword: "" })
            openNotification('Успех', 'Пароль клиента был изменен', 3, 4.5);
          }
        })
    }

  }

  render() {
    const columns = [
      {
        title: 'Фамилия',
        dataIndex: 'client_surname',
        key: 'client_surname',
        ...this.getColumnSearchProps('client_surname', 'по фамилии'),
      },
      {
        title: 'Имя',
        dataIndex: 'client_name',
        key: 'client_name',
        ...this.getColumnSearchProps('client_name', 'по имени'),
      },
      {
        title: 'Отчество',
        dataIndex: 'client_patronymic',
        key: 'client_patronymic',
        ...this.getColumnSearchProps('client_patronymic', 'по отчество'),
      },
      {
        title: 'Почта',
        dataIndex: 'client_mail',
        key: 'client_mail',
        ...this.getColumnSearchProps('client_mail', 'по почте'),
      },
      {
        title: 'Телефон',
        dataIndex: 'client_phone',
        key: 'client_phone',
        ...this.getColumnSearchProps('client_phone', 'по телефону'),
      },
      {
        title: 'Категория',
        dataIndex: 'client_category',
        key: 'client_category',
        ...this.getColumnSearchProps('client_category', 'по категории'),
      },
      {
        title: 'Фактический адрес',
        dataIndex: 'client_city',
        key: 'client_city',
        ...this.getColumnSearchProps('client_city', 'по Фактический адресу'),
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    return (
      <div>
        <div className="page_name">Клиенты</div>
        <Space size={10}>
          <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ client_window: true, thisNewClient: true }); this.clearNewClientData(); }}>Добавить клиента</Button>
          <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ loadusers: true }); }}>Загрузить клиентов из файла</Button>
          <Button type="default" style={{ marginBottom: "20px" }} onClick={() => { this.getClients(); openNotification('Успех', 'Данные обновлены', 3, 4.5); }}><RedoOutlined /></Button>
        </Space>

        <Modal title={"Загрузка клиентов"} open={this.state.loadusers} okText={"Добавить"} cancelText="Отмена" onOk={() => { this.uploadFile() }} onCancel={() => { this.setState({ loadusers: false }) }}>
          <form encType="multipart/form-data" >
            <label className="loadPlace" htmlFor="pc-upload-add" >
              <input style={{ display: "none" }} onChange={(e) => { this.setState({ loadInput: e, loadInputName: e.target.files[0].name }); console.log(e); }} type="file" id="pc-upload-add" multiple={false} />
              <span>{this.state.loadInputName}</span>
            </label>
          </form>
        </Modal>

        <Modal title={"Загрузка клиентов/Разбор полей"} open={this.state.parsingFieldsModal} okText={"Добавить"} cancelText="Отмена" onOk={() => { this.uploadClients(); }} onCancel={() => { this.setState({ parsingFieldsModal: false }) }}>
          <div>Сопоставьте поля из таблице с полями карточки клиента</div>
          <div className="parsingFields">
            <Divider orientation="left">Личные данные</Divider>
            <div className="flex">
              <div>Имя</div>
              <Select
                value={this.state.uploadClientName}
                style={{
                  width: 200,
                }}
                onChange={(value) => {this.setState({uploadClientName: value})}}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Фамилия</div>
              <Select
                value={this.state.uploadClientSurname}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientSurname: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Отчество</div>
              <Select
                value={this.state.uploadClientPatronymic}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientPatronymic: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Почта</div>
              <Select
                 value={this.state.uploadClientMail}
                 style={{
                   width: 200,
                 }}
                 onChange={(value) => { this.setState({uploadClientMail: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Раб. почта</div>
              <Select
                value={this.state.uploadClientWorkMail}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientWorkMail: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Доп. почта</div>
              <Select
                value={this.state.uploadClientAdditionalMail}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientAdditionalMail: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Телефон</div>
              <Select
                value={this.state.uploadClientPhone}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientPhone: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Раб. телефон</div>
              <Select
                 value={this.state.uploadClientWorkPhone}
                 style={{
                   width: 200,
                 }}
                 onChange={(value) => { this.setState({uploadClientWorkPhone: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Доп. телефон</div>
              <Select
                 value={this.state.uploadClientAdditionalPhone}
                 style={{
                   width: 200,
                 }}
                 onChange={(value) => { this.setState({uploadClientAdditionalPhone: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Фактический адрес</div>
              <Select
                value={this.state.uploadClientCity}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientCity: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Адрес по прописке</div>
              <Select
                value={this.state.uploadClientCity2}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientCity2: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>СНИЛС</div>
              <Select
                value={this.state.uploadClientSnils}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientSnils: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <Divider orientation="left">Данные об образовании</Divider>
            <div className="flex">
              <div>ФИО в дипломе</div>
              <Select
                value={this.state.uploadClientFioDiplom}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientFioDiplom: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Серия диплома</div>
              <Select
                value={this.state.uploadClientSeriesDiplom}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientSeriesDiplom: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Номер диплома</div>
              <Select
                value={this.state.uploadClientNumberDiplom}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientNumberDiplom: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Учреждение</div>
              <Select
                value={this.state.uploadClientInstitution}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientInstitution: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <Divider orientation="left">Данные по обучению</Divider>
            <div className="flex">
              <div>Серия документа</div>
              <Select
                value={this.state.uploadClientDocSeries}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientDocSeries: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Номер документа</div>
              <Select
                value={this.state.uploadClientDocNumber}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientDocNumber: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Дата документа</div>
              <Select
                value={this.state.uploadClientDocDate}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientDocDate: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Регистрационный номер</div>
              <Select
                value={this.state.uploadClientRegistrationNumber}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientRegistrationNumber: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Категория</div>
              <Select
                value={this.state.uploadClientCategory}
                style={{
                  width: "calc(100% - 92px)",
                }}
                onChange={(value) => { this.setState({uploadClientCategory: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
            <div className="flex">
              <div>Ссылка на платформу фед. оператора</div>
              <Select
                value={this.state.uploadClientFedLink}
                style={{
                  width: 200,
                }}
                onChange={(value) => { this.setState({uploadClientFedLink: value}) }}
              >
                {
                  this.state.uploadFileJsonFields.length !== 0 ?
                    this.state.uploadFileJsonFields.map((elem, index) => {
                      return (
                        <Option key={elem} value={elem}>
                          {elem.value}
                        </Option>
                      )
                    }) : "Список пустой :("
                }
              </Select>
            </div>
          </div>
        </Modal>

        <Table columns={columns} dataSource={this.state.clients} />

        <Drawer
          title={this.state.thisNewClient === true ? "Новый клиент " + this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic : this.state.newClientSurname + " " + this.state.newClientName + " " + this.state.newClientPatronymic}
          placement="bottom"
          height={"100vh"}
          onClose={() => { this.closeDrawer(); }}
          open={this.state.client_window}
          extra={
            <Space>
              {
                this.state.thisNewClient === true && this.state.inputChanged === true && this.state.newClientSurname !== "" && this.state.newClientName !== "" && this.state.newClientPhone !== "" && this.state.newClientMail !== "" && this.state.newClientPassword !== "" ?
                  <Button type="primary" onClick={() => { this.createClient(); }}>
                    Добавить клиента
                  </Button>
                  :
                  ""
              }

              {
                this.state.thisNewClient === false && this.state.inputChanged === true ?
                  <Button type="primary" onClick={() => { this.patchClient(); }}>
                    Сохранить изменения
                  </Button>
                  :
                  ""
              }

              <Button onClick={() => { this.closeDrawer() }}>Закрыть</Button>
            </Space>
          }
        >
          {
            this.state.thisNewClient === true ?
              <Alert
                message={"ВНИМАНИЕ! Для создания клиента и дальнейшей его идентификации необходимо заполнить поля: имя, фамилия, телефон, почта и пароль. Надеемся на понимание, это сделано для вашего же удобства."}
                type="warning"
                banner
              />
              : ""
          }
          <Row gutter={[16, 16]}>
            <Col flex={1}>
              <Divider orientation="left">Личные данные</Divider>
              <Space direction="vertical">
                <Input addonBefore="Имя:" name="newClientName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientName} />
                <Input addonBefore="Фамилия:" name="newClientSurname" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSurname} />
                <Input addonBefore="Отчество:" name="newClientPatronymic" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPatronymic} />
                <Input addonBefore="Почта:" name="newClientMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientMail} placeholder="xxx@xxx.xx" />
                <Input addonBefore="Раб. почта:" name="newClientWorkMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkMail} placeholder="xxx@xxx.xx" />
                <Input addonBefore="Доп. почта:" name="newClientAdditionalMail" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalMail} placeholder="xxx@xxx.xx" />
                <Input addonBefore="Телефон:" name="newClientPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientPhone} placeholder="+7xxxxxxxxxx" />
                <Input addonBefore="Раб. телефон:" name="newClientWorkPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientWorkPhone} placeholder="+7xxxxxxxxxx" />
                <Input addonBefore="Доп. телефон:" name="newClientAdditionalPhone" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientAdditionalPhone} placeholder="+7xxxxxxxxxx" />
                <Input addonBefore="Фактический адрес:" name="newClientCity" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity} />
                <Input addonBefore="Адрес прописки:" name="newClientCity2" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientCity2} />
                <Input.Group compact>
                  <span className="input_line_name">День рождения:</span>
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      allowClear={false}
                      value={dayjs(this.state.newClientBirthday, 'DD.MM.YYYY')}
                      format={'DD.MM.YYYY'}
                      onChange={(value) => { this.setState({ newClientBirthday: value }) }}
                    />
                  </ConfigProvider>
                </Input.Group>
                <Input.Group compact>
                  <span className="input_line_name">Пол:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientPol}
                      style={{
                        width: 120,
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientPol') }}
                      options={[
                        {
                          value: 'Жен',
                          label: 'Жен',
                        },
                        {
                          value: 'Муж',
                          label: 'Муж',
                        }
                      ]}
                    />
                  </ConfigProvider>
                </Input.Group>
                <Input addonBefore="СНИЛС:" name="newClientSnils" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSnils} placeholder="xxx-xxx-xxx xx" />
              </Space>
            </Col>
            <Col flex={1}>
              <Divider orientation="left">Данные об образовании</Divider>
              <Space direction="vertical">
                <Input.Group compact>
                  <span className="input_line_name">Уровень образования:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientLevelDiplom}
                      style={{
                        width: 200,
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientLevelDiplom') }}
                      options={[
                        {
                          value: 'Высшее образование',
                          label: 'Высшее образование',
                        },
                        {
                          value: 'Среднее профессиональное образование',
                          label: 'Среднее профессиональное образование',
                        },
                        {
                          value: 'Справка',
                          label: 'Справка',
                        },
                        {
                          value: 'Стаж',
                          label: 'Стаж',
                        },
                        {
                          value: 'Пункт 1 правил формирования ФИС ФРДО',
                          label: 'Пункт 1 правил формирования ФИС ФРДО',
                        }
                      ]}
                    />
                  </ConfigProvider>
                </Input.Group>
                <Input addonBefore="ФИО в дипломе:" name="newClientFioDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFioDiplom} />
                <Input addonBefore="Серия диплома:" name="newClientSeriesDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientSeriesDiplom} />
                <Input addonBefore="Номер диплома:" name="newClientNumberDiplom" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientNumberDiplom} />
                <Input addonBefore="Учреждение:" name="newClientInstitution" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientInstitution} />
              </Space>
            </Col>
            <Col flex={1}>
              <Divider orientation="left">Данные по обучению</Divider>
              <Space direction="vertical">
                <Input.Group compact>
                  <span className="input_line_name">Статус:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientStatus}
                      style={{
                        width: 200,
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientStatus') }}
                      options={[
                        {
                          value: 'Обучается',
                          label: 'Обучается',
                        },
                        {
                          value: 'Обучился',
                          label: 'Обучился',
                        },
                        {
                          value: 'Отказался',
                          label: 'Отказался',
                        },
                        {
                          value: 'Не сдал итоговую',
                          label: 'Не сдал итоговую',
                        }
                      ]}
                    />
                  </ConfigProvider>
                </Input.Group>
                <Input addonBefore="Серия документа:" value={this.state.newClientDocSeries} name="newClientDocSeries" onChange={this.ChangeInputValue.bind(this)} />
                <Input addonBefore="Номер документа:" value={this.state.newClientDocNumber} name="newClientDocNumber" onChange={this.ChangeInputValue.bind(this)} />
                <Input addonBefore="Дата документа:" value={this.state.newClientDocDate} name="newClientDocDate" onChange={this.ChangeInputValue.bind(this)} />
                <Input addonBefore="Регистрационный номер:" value={this.state.newClientRegistrationNumber} name="newClientRegistrationNumber" onChange={this.ChangeInputValue.bind(this)} />
                <Input.Group compact>
                  <span className="input_line_name">Группа:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientGroupId}
                      style={{
                        width: 200,
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientGroupId'); this.setState({ newClientCourseId: this.state.groups.find(group => group.group_id == value).course_id, newClientProjectId: this.state.groups.find(group => group.group_id == value).project_id }) }}
                    >
                      {
                        this.state.groups.length !== 0 ?
                          this.state.groups.map((item, index) => {
                            return (
                              <Option key={index} value={item.group_id}>
                                {item.group_name}
                              </Option>
                            )
                          }) : "Список пустой :("
                      }
                    </Select>
                  </ConfigProvider>
                </Input.Group>
                <Input addonBefore="Курс:" disabled value={this.state.newClientGroupId !== "" && this.state.newClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.newClientGroupId).course_name : ""} />
                <Input addonBefore="Проект:" disabled value={this.state.newClientGroupId !== "" && this.state.newClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.newClientGroupId).project_name : ""} />
                <Input addonBefore="Год:" disabled value={this.state.newClientGroupId !== "" && this.state.newClientGroupId !== null && this.state.groups.length !== 0 ? this.state.groups.find(group => group.group_id == this.state.newClientGroupId).project_year : ""} />
                <Input addonBefore="Цена:" value={this.state.newClientPrice} name="newClientPrice" onChange={this.ChangeInputValue.bind(this)} />
                <Input.Group compact>
                  <span className="input_line_name">Категория:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientCategory}
                      style={{
                        width: "calc(100% - 92px)",
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientCategory') }}
                      options={[
                        {
                          value: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                          label: 'Граждане, не получающие доход и состоящие на учете в центре занятости',
                        },
                        {
                          value: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                          label: 'Отцы или матери, имеющие детей в возрасте до 3 лет, с ЗП ниже средней по региону',
                        },
                        {
                          value: 'Студенты',
                          label: 'Студенты',
                        },
                        {
                          value: 'Граждане с заработной платой ниже средней по субъекту РФ',
                          label: 'Граждане с заработной платой ниже средней по субъекту РФ',
                        },
                        {
                          value: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                          label: 'Граждане, не получающие доход и не состоящие на учете в центре занятости',
                        },
                        {
                          value: 'Инвалиды',
                          label: 'Инвалиды',
                        },
                        {
                          value: 'Работники бюджетных организаций',
                          label: 'Работники бюджетных организаций',
                        },
                        {
                          value: 'Безработные граждане, зарегистрированные в органах службы занятости',
                          label: 'Безработные граждане, зарегистрированные в органах службы занятости',
                        },
                        {
                          value: 'Ветераны боевых действий принимавшие участие в СВО',
                          label: 'Ветераны боевых действий принимавшие участие в СВО',
                        },
                        {
                          value: 'Граждане в возрасте 50-ти лет и старше',
                          label: 'Граждане в возрасте 50-ти лет и старше',
                        },
                        {
                          value: 'Граждане до 35 лет включительно, находящиеся под риском увольнения',
                          label: 'Граждане до 35 лет включительно, находящиеся под риском увольнения',
                        },
                        {
                          value: 'Граждане до 35 лет включительно, не занятые по истечении 4 месяцев с даты выдачи им документа об образовании и о квалификации',
                          label: 'Граждане до 35 лет включительно, не занятые по истечении 4 месяцев с даты выдачи им документа об образовании и о квалификации',
                        },
                        {
                          value: 'Граждане до 35 лет включительно, не занятые по истечении 4 месяцев с даты окончания военной службы по призыву',
                          label: 'Граждане до 35 лет включительно, не занятые по истечении 4 месяцев с даты окончания военной службы по призыву',
                        },
                        {
                          value: 'Граждане до 35 лет включительно, не имеющие среднего профессионального и высшего образования',
                          label: 'Граждане до 35 лет включительно, не имеющие среднего профессионального и высшего образования',
                        },
                        {
                          value: 'Граждане до 35 лет включительно, обучающиеся на последних курсах по образовательным программам среднего профессионального или высшего образования',
                          label: 'Граждане до 35 лет включительно, обучающиеся на последних курсах по образовательным программам среднего профессионального или высшего образования',
                        },
                        {
                          value: 'Граждане предпенсионного возраста',
                          label: 'Граждане предпенсионного возраста',
                        },
                        {
                          value: 'Женщины, имеющие детей дошкольного возраста и не состоящие в трудовых отношениях',
                          label: 'Женщины, имеющие детей дошкольного возраста и не состоящие в трудовых отношениях',
                        },
                        {
                          value: 'Женщины, находящиеся в отпуске по уходу за ребенком в возрасте до трех лет',
                          label: 'Женщины, находящиеся в отпуске по уходу за ребенком в возрасте до трех лет',
                        },
                        {
                          value: 'Лица, получившие удостоверение беженца прибывшие в Российскую Федерацию с территорий Украины, ЛНР и ДНР',
                          label: 'Лица, получившие удостоверение беженца прибывшие в Российскую Федерацию с территорий Украины, ЛНР и ДНР',
                        },
                        {
                          value: 'Лица, принимавшие участие в боевых действиях',
                          label: 'Лица, принимавшие участие в боевых действиях',
                        },
                        {
                          value: 'Лица, принимавшие участие в боевых действиях',
                          label: 'Лица, принимавшие участие в боевых действиях',
                        },
                        {
                          value: 'Лица, принимавшие участие в боевых действиях',
                          label: 'Лица, принимавшие участие в боевых действиях',
                        },
                        {
                          value: 'Работники, находящиеся под риском увольнения',
                          label: 'Работники, находящиеся под риском увольнения',
                        },
                        {
                          value: 'Члены семей лиц, погибших (умерших) ветеранов боевых действий СВО',
                          label: 'Члены семей лиц, погибших (умерших) ветеранов боевых действий СВО',
                        }
                      ]}
                    />
                  </ConfigProvider>
                </Input.Group>
                <Input.Group compact>
                  <span className="input_line_name">Платформа обучения:</span>
                  <ConfigProvider locale={locale}>
                    <Select
                      value={this.state.newClientPlatformId}
                      style={{
                        width: 200,
                      }}
                      onChange={(value) => { this.ChangeSelect(value, 'newClientPlatformId'); }}
                    >
                      {
                        this.state.platforms.length !== 0 ?
                          this.state.platforms.map((platform, index) => {
                            return (
                              <Option key={index} value={platform.platform_id}>
                                {platform.platform_name}
                              </Option>
                            )
                          }) : "Список пустой :("
                      }
                    </Select>
                  </ConfigProvider>
                </Input.Group>
                <Space direction="horizontal">
                  <Input addonBefore="Профиль в платформе фед. оператора:" name="newClientFedLink" onChange={this.ChangeInputValue.bind(this)} value={this.state.newClientFedLink} />
                  <Button
                    style={{
                      width: 80,
                    }}
                    onClick={() => {window.open(this.state.newClientFedLink, '_blank').focus();}}
                  >
                    <EyeOutlined />
                  </Button>
                </Space>
              </Space>
            </Col>
          </Row>
          <Modal title={"Изменение пароля клиента"} open={this.state.clientPassEdit} okText={"Изменить"} cancelText="Отмена" onOk={() => { this.editClientPassword() }} onCancel={() => { this.setState({ clientPassEdit: false }) }}>
            <Input addonBefore="Новый пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
          </Modal>
          <Row gutter={[16, 16]}>
            <Col flex={1}>
              <Divider orientation="left">Данные авторизации</Divider>
              {
                this.state.thisNewClient === true ?
                  <Input addonBefore="Пароль:" value={this.state.newClientPassword} name="newClientPassword" onChange={(e) => { this.setState({ newClientPassword: e.target.value }) }} />
                  :
                  <Space direction="vertical">
                    <Input addonBefore="Логин:" value={this.state.newClientLogin} name="newClientLogin" onChange={this.ChangeInputValue.bind(this)} />
                    <Button type="primary" onClick={() => { this.setState({ clientPassEdit: true }) }}>Изменить пароль</Button>
                  </Space>
              }
            </Col>
          </Row>

        </Drawer>

      </div>
    );


  }

  componentDidMount() {
    this.getClients();
    this.getGroups();
    this.getPlatforms();
  }

}