import React, { version } from "react";
import { openNotification } from "../functions";
import config from "../config.json";
import { getYaData, reqDownloadElement, reqDeleteElement, reqAddFolder, reqAddFile } from "../YaFunctions";
import axios from "axios";
import { Table, Button, Modal, Input, Popconfirm, Select, Space } from 'antd';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import {
  FolderOpenFilled,
  FileExcelFilled,
  FilePdfFilled,
  MacCommandFilled,
  BlockOutlined
} from '@ant-design/icons';
import LoadingScreen from "../components/loading_screen";

export default class TestYandex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      diskEntity: [],
      test: "",
      loading: false,
      breadCrumb: "",
      page: "disk:/",
      diskSelectedElement: "",
      createFolder: false,
      createFile: false,
      newFolderName: ""
    };
  }

  async goPage(path) {
    this.setState({ loading: true });
    var result = await getYaData(path);
    var breadCrumb = result.path.split('/');
    breadCrumb.pop();
    this.setState({ diskEntity: result._embedded.items, loading: false, page: result.path, breadCrumb: breadCrumb });
  }

  async downloadE(path) {
    this.setState({ loading: true });
    var result = await reqDownloadElement(path);
    window.open(result.href);
    this.setState({ loading: false });
  }

  async deleteE(path) {
    this.setState({ loading: true });
    var result = await reqDeleteElement(path);
    var splitPath = path.split("/");
    splitPath.pop();
    if(splitPath.length === 1) {
      this.goPage("/");
    }else{
      this.goPage(splitPath.join("/"));
    }
    this.setState({ loading: false });
  }

  async addFolder() {
    this.setState({ loading: true });
    if(this.state.page === "disk:/") {
      var result = await reqAddFolder("/" + this.state.newFolderName);
    }else{
      var result = await reqAddFolder(this.state.page.split("disk:")[1] + "/" + this.state.newFolderName);
    }
    
    this.goPage(this.state.page);
    this.setState({ loading: false, createFolder: false, newFolderName: "" });
  }

  async addFile() {
    console.log(this.state.page.split('disk:')[1]);
    this.setState({ loading: true });
    var result;
    if (this.state.page.split('disk:')[1] === "/") {
      result = await reqAddFile(encodeURI(this.state.page.split('disk:')[1] + document.getElementById('upload-file').value.split("\\").pop()));
    }else{
      result = await reqAddFile(encodeURI(this.state.page.split('disk:')[1] + "/" + document.getElementById('upload-file').value.split("\\").pop()));
    }
    console.log(result);
    this.goPage(this.state.page);
    this.setState({ loading: false, createFile: false });
  }

  trimLine(str) {
    if (str.length > 25) {
      return (str.slice(0, 9) + "..." + str.slice(str.length - 7))
    } else {
      return (str);
    }
  }

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  }


  render() {
    return (
      <div>
        {
          this.state.page === 'disk:/' ?
            ""
            :
            <Button type="text" style={{ display: "block" }} onClick={() => {
              var pagesArr = this.state.page.split("/");
              pagesArr.pop();
              if (pagesArr.length === 1) {
                this.goPage('/');
              } else {
                this.goPage(pagesArr.join('/'));
              }
            }}>
              ← Назад
            </Button>
        }
        <Space>
          <Button onClick={() => {this.setState({createFile: true})}}>Добавить файл</Button>
          <Button onClick={() => {this.setState({createFolder: true})}}>Создать папку</Button> 
        </Space>
        <div className="diskBreadCrumb">
          {
            this.state.breadCrumb === "" || this.state.breadCrumb.length === 1 ?
              ""
              :
              this.state.breadCrumb.map((elem, index) => {
                if (elem === 'disk:') {
                  return (<span key={index} className="diskBreadCrumbItem" onClick={() => { this.goPage('/') }}>{"Главная > "}</span>);
                }
                return (<span key={index} onClick={() => { this.goPage(elem) }} className="diskBreadCrumbItem" >{elem + " > "}</span>);
              })
          }
        </div>

        <Modal title="Создать папку" okText="Создать" cancelText="Закрыть" open={this.state.createFolder} onOk={() => {this.addFolder()}} onCancel={() => {this.setState({createFolder: false})}}>
          <Input addonBefore="Название:" value={this.state.newFolderName} name="newFolderName" type="text" onChange={this.onChange.bind(this)}/>
        </Modal>

        <Modal title="Нажмите на серое поля для загрузки или перетащите файл" okText="Добавить" cancelText="Закрыть" open={this.state.createFile} onOk={() => {this.addFile()}} onCancel={() => {this.setState({createFile: false})}}>
          <Input type="file" name="newFile" id="upload-file" />
        </Modal>

        <div className="diskPageTitle">{this.state.page.split('/').pop()}</div>

        <ContextMenu id="contextmenu" hideOnLeave={true} >
          {/* onMouseLeave={() => { this.setState({ diskSelectedElement: "" }) }} */}
          <MenuItem>
            <span onClick={() => { this.downloadE(this.state.diskSelectedElement.path) }}>Скачать</span>
          </MenuItem>
          <MenuItem >
            <Popconfirm
              title="Вы уверены что хотите удалить файл?"
              okText="Да"
              onConfirm={() => { this.deleteE(this.state.diskSelectedElement.path) }}
              cancelText="Нет"
            >
              <span>Удалить</span>
            </Popconfirm>
          </MenuItem>
        </ContextMenu>

        {
          this.state.loading === true ?
            <LoadingScreen />
            :
            this.state.diskEntity.map((elem, index) => {
              if (elem.type === 'dir') {
                return (

                  <div className="diskItem" onClick={() => { this.goPage(elem.path) }} key={index}>
                    <ContextMenuTrigger id="contextmenu" collect={() => { this.setState({ diskSelectedElement: elem }) }}>
                      <div className="diskItemBody">
                        <div className="diskItemImage">
                          <img src={"/diskIcon/folder.png"} alt="file_preview" />
                        </div>
                        <div className="diskItemName">{this.trimLine(elem.name)}</div>
                      </div>
                    </ContextMenuTrigger>
                  </div>
                )
              } else if (elem.type === 'file') {
                return (
                  <div className="diskItem" key={index}>
                    <ContextMenuTrigger id="contextmenu" collect={() => { this.setState({ diskSelectedElement: elem }) }}>
                      <div className="diskItemBody">
                        <div className="diskItemImage">
                          <img src={"/diskIcon/" + elem.name.split('.')[1] + ".png"} onError={(e) => { e.target.src = '/diskIcon/undefined.png' }} alt="не загружено" />
                        </div>
                        <div className="diskItemName">{this.trimLine(elem.name)}</div>
                      </div>
                    </ContextMenuTrigger>
                  </div>
                )
              }
            })
        }

      </div>
    );
  }

  async componentDidMount() {
    this.goPage('/');
  }

}
