import "./App.css";
import React from 'react';
import Login from './pages/login';
import Main from './pages/main';
import {
  RedditOutlined,
  BankOutlined,
  IssuesCloseOutlined,
  AppstoreAddOutlined,
  ProjectOutlined,
  PieChartOutlined,
  TeamOutlined,
  DownloadOutlined,
  GroupOutlined,
  HomeOutlined,
  FileOutlined,
  InsertRowLeftOutlined,
  UnorderedListOutlined,
  QrcodeOutlined,
  BookOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Route, useNavigate, Routes } from 'react-router-dom';
import menu_logo from './images/menu_logo.png';
import mini_logo from './images/mini_logo.png';
import Clients from "./pages/clients";
import Users from "./pages/users";
import Projects from "./pages/projects";
import Courses from "./pages/courses";
import Groups from "./pages/groups";
import Frdo from "./pages/frdo";
import Unloading from "./pages/unloading";
import Statistics from "./pages/statistics";
import Files from "./pages/files";
import Me from "./pages/me";
import TestYandex from "./pages/testyandex";
import Platforms from "./pages/platforms";
import Learning from "./pages/learning";

const { Content, Sider } = Layout;

function getMenuItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const admin_items = [
  getMenuItem('Главная', '/main', <HomeOutlined />),
  getMenuItem('Пользователи', '/users', <TeamOutlined />),
  getMenuItem('Платформы обучения', '/platforms', <BookOutlined />),
  getMenuItem('Проекты', '/progects_menu', <ProjectOutlined />, [
    getMenuItem('Список проектов', '/Projects', <UnorderedListOutlined />),
    getMenuItem('Курсы', '/courses', <BankOutlined />),
    getMenuItem('Группы', '/groups', <AppstoreAddOutlined />)
  ]),
  getMenuItem('Клиенты', '/clients_menu', <RedditOutlined />, [
    getMenuItem('Список клиентов', '/clients', <TeamOutlined />),
    getMenuItem('Статусы', '/statuses', <IssuesCloseOutlined />),
  ]),
  // getMenuItem('Файлы', '/files', <GroupOutlined />),
  getMenuItem('Договоры', '/contracts', <FileOutlined />),
  getMenuItem('Выгрузка', '/unloading', <DownloadOutlined />),
  getMenuItem('ФРДО', '/frdo', <InsertRowLeftOutlined />),
  getMenuItem('Цифровой след', '/digital_footprint', <QrcodeOutlined />),
  getMenuItem('Статистика', '/statistics_menu', <PieChartOutlined />, [
    getMenuItem('Tom', '/statistics'),
    getMenuItem('Bill', '5'),
    getMenuItem('Alex', '6'),
  ]),
  getMenuItem('Файлы', '/testyandex', <QrcodeOutlined />),
  getMenuItem('Выход', '/', <QrcodeOutlined />),
];

const client_items = [
  getMenuItem('Мой профиль', '/me', <HomeOutlined />),
  getMenuItem('Мое обучение', '/learning', <TeamOutlined />),
  getMenuItem('Выход', '/', <QrcodeOutlined />),
];

const users_items = [
  getMenuItem('Главная', '/main', <HomeOutlined />),
  getMenuItem('Проекты', '/progects_menu', <ProjectOutlined />, [
    getMenuItem('Список проектов', '/Projects', <UnorderedListOutlined />),
    getMenuItem('Курсы', '/courses', <BankOutlined />),
    getMenuItem('Группы', '/groups', <AppstoreAddOutlined />)
  ]),
  getMenuItem('Клиенты', '/clients_menu', <RedditOutlined />, [
    getMenuItem('Список клиентов', '/clients', <TeamOutlined />),
    getMenuItem('Статусы', '/statuses', <IssuesCloseOutlined />),
  ]),
  getMenuItem('Файлы', '/files', <GroupOutlined />),
  getMenuItem('Договоры', '/contracts', <FileOutlined />),
  getMenuItem('Выгрузка', '/unloading', <DownloadOutlined />),
  getMenuItem('ФРДО', '/frdo', <InsertRowLeftOutlined />),
  getMenuItem('Цифровой след', '/digital_footprint', <QrcodeOutlined />),
  getMenuItem('Статистика', '/statistics_menu', <PieChartOutlined />, [
    getMenuItem('Tom', '/statistics'),
    getMenuItem('Bill', '5'),
    getMenuItem('Alex', '6'),
  ]),
  getMenuItem('Выход', '/', <QrcodeOutlined />),
];


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      login: false,
      collapsed: localStorage.getItem('siderCollapsed') === 'true' ? true : false
    }
  }

  exit() {
    localStorage.removeItem("token");
    this.props.navigate("/");
  }
  // if (!localStorage.getItem('token') && window.location.pathname !== '/login') {
  //   this.props.navigate('/login');
  // }

  render() {
    
    
    return (
      
      <Layout style={{ minHeight: '100vh' }}>
        {
          window.location.pathname === '/' ?
            ""
            :
            <Sider collapsible collapsed={this.state.collapsed} onCollapse={(value) => {this.setState({ collapsed: value }); localStorage.setItem('siderCollapsed', value)}}>
              <img alt="logo" style={{ padding: '20px', width: '100%' }} src={localStorage.getItem('siderCollapsed') === 'true' ? mini_logo : menu_logo} />
              {
                localStorage.getItem('rer') === '99' ?
                  <Menu onClick={(e) => { this.props.navigate(e.key) }} defaultSelectedKeys={['1']} mode="inline" items={client_items} />
                  :
                  <Menu onClick={(e) => { this.props.navigate(e.key) }} defaultSelectedKeys={['1']} mode="inline" items={admin_items} />
              }
            </Sider>
        }

        <Layout className="site-layout">
          <Content style={{ margin: window.location.pathname === '/learning' ? '0px' : '16px' }}>
            <div className="wrapper">
              <Routes>
                {
                  localStorage.getItem('rer') === '99' ? <Route path="/me" element={<Me navigateFunc={(a) => this.props.navigate(a)} />}></Route> : <Route path="/main" element={<Main navigateFunc={(a) => this.props.navigate(a)} />}></Route>
                }
                <Route path="/" element={<Login exact navigateFunc={(a) => this.props.navigate(a)} />}></Route>
                <Route path="/clients" element={<Clients />}></Route>
                {
                  localStorage.getItem('rer') === 2 ? "" : <Route path="/users" element={<Users />}></Route>
                }
                <Route path="/unloading" element={<Unloading />}></Route>
                <Route path="/testyandex" element={<TestYandex />}></Route>
                <Route path="/projects" element={<Projects />}></Route>
                <Route path="/courses" element={<Courses />}></Route>
                <Route path="/groups" element={<Groups />}></Route>
                <Route path="/frdo" element={<Frdo />}></Route>
                <Route path="/files" element={<Files />}></Route>
                <Route path="/statistics" element={<Statistics />}></Route>
                <Route path="/platforms" element={<Platforms />}></Route>
                <Route path="/learning" element={<Learning />}></Route>
              </Routes>
            </div>
          </Content>
        </Layout>
        <div className="vers">student-control V 2.0</div>
      </Layout>
    );
  }
};

function WithNavigate(props) {
  let navigate = useNavigate();
 
  return <App {...props} navigate={navigate} />
}

export default WithNavigate;
// export default App;

