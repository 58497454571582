import React from "react";
import {
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Table, Button, Modal, Input, Popconfirm, Select, DatePicker, ConfigProvider } from 'antd';
// import config from '../config.json';
import { openNotification } from "../functions";
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';

export default class Groups extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0,
      modal: false,
      courses: [],
      groups: [],
      projects: [],
      newGroupName: '',
      newGroupCourse: '',
      newGroupDateStart: '',
      newGroupDateEnd: '',
      newGroupId: '',
      newGroupDuration: '',
      edit: false
    };
  }

  getGroups() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          var arr = [];
          response.data.map((group) => {
            arr.push({
              key: group.group_id,
              group_name: group.group_name,
              course_name: group.course_name,
              project_year: group.project_year,
              time_start: group.time_start,
              time_end: group.time_end,
              project_name: group.project_name,
              duration: group.duration,
              events: <div>
                <EyeOutlined onClick={() => {
                  this.setState({ edit: true, newGroupName: group.group_name, newGroupId: group.group_id, newGroupCourse: group.course_id, newGroupDateStart: group.time_start, newGroupDateEnd: group.time_end, newGroupDuration: group.duration, modal: true })
                }} style={{ cursor: 'pointer', marginRight: '10px' }} />
                <Popconfirm
                  title="Вы уверены что хотите удалить группу?"
                  okText="Да"
                  onConfirm={() => { this.deleteGroup(group.group_id); }}
                  cancelText="Нет"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            })
          })
          this.setState({ groups: arr });
        }
      })
  }

  getCourses() {
    fetch(`https://student-control.nti-center.ru/api/courses`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'GET',
      cache: 'no-cache',
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          this.setState({ courses: response.data });
        }
      })
  }

  postGroup() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        "group_name": this.state.newGroupName,
        "course_id": this.state.newGroupCourse,
        "time_start": this.state.newGroupDateStart,
        "time_end": this.state.newGroupDateEnd,
        "duration" : this.state.newGroupDuration,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Вы создали группу!', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getGroups();
        }
      });
  }
  
  deleteGroup(id) {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'DELETE',
      cache: 'no-cache',
      body: JSON.stringify({
        "group_id": id
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Группа была удалена', 3, 4.5);
          this.getGroups();
        }
      })
  }

  patchGroup() {
    fetch(`https://student-control.nti-center.ru/api/groups`, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'CokCRM ' + localStorage.getItem('token')
      }),
      method: 'PATCH',
      cache: 'no-cache',
      body: JSON.stringify({
        "group_id" : this.state.newGroupId,
        "group_name" : this.state.newGroupName,
        "course_id" : this.state.newGroupCourse,
        "time_start" : this.state.newGroupDateStart,
        "time_end" : this.state.newGroupDateEnd,
        "duration" : this.state.newGroupDuration,
      })
    })
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          openNotification('Ошибка', response.error, 1, 4.5);
        } else {
          openNotification('Успех', 'Группа была изменёна', 3, 4.5);
          if (this.state.modal === true) {
            this.setState({ modal: false });
          }
          this.getGroups();
        }
      })
  }

  ChangeInputValue = (e) => {
    let name = e.target.name;
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [name]: value });
  }

  CheckFields() {
    if (this.state.newGroupName === "" || this.state.newGroupCourse === "" || this.state.newGroupDateStart === "" || this.state.newGroupDateEnd === "" || this.state.newGroupDuration === "") {
      return openNotification('Ошибка', 'Заполните пустые поля', 1, 4.5);
    } else {
      if (this.state.edit === true) {
        this.patchGroup();
      } else {
        this.postGroup();
      }
    }
  }

  onChangeDate(date, dateString) {
    this.setState({newGroupDateStart: dateString[0], newGroupDateEnd: dateString[1]});
  }

  render() {

    const columns = [
      {
        title: 'Название',
        dataIndex: 'group_name',
        key: 'group_name',
      },
      {
        title: 'Время начала',
        dataIndex: 'time_start',
        key: 'time_start',
      },
      {
        title: 'Время окончания',
        dataIndex: 'time_end',
        key: 'time_end',
      },
      {
        title: 'Курс',
        dataIndex: 'course_name',
        key: 'course_name',
      },
      {
        title: 'Проект',
        dataIndex: 'project_name',
        key: 'project_name',
      },
      {
        title: 'Год',
        dataIndex: 'project_year',
        key: 'project_year',
      },
      {
        title: 'Длительность',
        dataIndex: 'duration',
        key: 'duration',
      },
      {
        title: 'Действия',
        dataIndex: 'events',
        key: 'events',
      }
    ];

    return (
      <div>
        <div className="page_name">Список групп</div>
        <Button type="primary" style={{ marginBottom: "20px" }} onClick={() => { this.setState({ edit: false, newGroupName: '', newGroupCourse: '', newGroupId: '', newGroupDateStart: "00.00.0000", newGroupDateEnd: "00.00.0000", newGroupDuration: "", modal: true }) }}>Добавить группу</Button>
        <Table dataSource={this.state.groups} columns={columns} />

        <Modal title={this.state.edit === true ? "Редактирование группы" : "Добавление группы"} open={this.state.modal} okText={this.state.edit === true ? "Сохранить" : "Добавить"} cancelText="Отмена" onOk={() => { this.CheckFields(); }} onCancel={() => { this.setState({ modal: false }) }}>
          <div className="modal_field_name">Название группы</div>
          <Input name="newGroupName" onChange={this.ChangeInputValue.bind(this)} value={this.state.newGroupName} placeholder="Введите название" />
          <div className="modal_field_name">Курс</div>
          <Select
            style={{
              width: '100%'
            }}
            value={this.state.newGroupCourse}
            onChange={(value) => { this.setState({ newGroupCourse: value }) }}
            options={
              this.state.courses.map((course) => {
                return {
                  value: course.course_id,
                  label: course.course_name + " - " + course.project_name + " " + course.project_year
                }
              })
            }
          />
          <div className="modal_field_name">Срок обучения группы</div>
          <ConfigProvider locale={locale}>
            <DatePicker.RangePicker allowClear={false} value={[dayjs(this.state.newGroupDateStart, 'DD.MM.YYYY'), dayjs(this.state.newGroupDateEnd, 'DD.MM.YYYY')]} format={'DD.MM.YYYY'} onChange={(date, dateString) => {this.onChangeDate(date, dateString)}} placeholder={['Выберите дату начала','Выберите дату окончания']} style={{ width: '100%' }} />
          </ConfigProvider>  
          <div className="modal_field_name">Длительность (в часах)</div>        
          <Select
            style={{
              width: '100%'
            }}
            value={this.state.newGroupDuration}
            onChange={(value) => { this.setState({ newGroupDuration: value }) }}
            options={
              [
                {
                  value: 72,
                  label: 72
                },
                {
                  value: 144,
                  label: 144
                },
                {
                  value: 256,
                  label: 256
                },
                {
                  value: 272,
                  label: 272
                },
              ]
            }
          />
        </Modal>
      </div>
    );
  }

  componentDidMount() {
    this.getGroups();
    this.getCourses();
  }

}