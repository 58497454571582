import React from "react";
import 'animate.css';
import { StepBackwardFilled } from "@ant-design/icons";
import { Card } from 'antd';

export default class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeBlock: 0
    };
  }

  // checkToken() {
  //   if (!localStorage.getItem('token') && window.location.pathname !== '/login') {
  //     this.props.navigateFunc("/login");
  //   }
  // }

  render() {
    
    return (
      <div className="content">
        <div className="page_name">Привет пользователь</div>        
      </div>
    );
  }

  componentDidMount() {
  }

}